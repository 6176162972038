.glitch-img {
    position: relative;
    display: inline-block;
}
.glitch-img img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    animation: glitch-anim 2.5s infinite;
    opacity: 0.8;
    clip-path: inset(0px 0 0 0px);
}

.glitch-img img:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    animation: glitch-anim2 3s infinite;
    opacity: 0.8;
    clip-path: inset(0px 0 0 0px);
}

@keyframes glitch-anim {
    0% {
        clip-path: inset(0px calc(100% - 95%) calc(100% - 95%) 0px); /* 5% visible */
        transform: translate(0px, 0px);
    }
    20% {
        clip-path: inset(5px calc(100% - 92%) calc(100% - 90%) 5px); /* 8-10% visible */
        transform: translate(15px, 10px);
    }
    40% {
        clip-path: inset(10px calc(100% - 97%) calc(100% - 96%) 5px); /* 3-4% visible */
        transform: translate(-20px, 15px);
    }
    60% {
        clip-path: inset(8px calc(100% - 94%) calc(100% - 93%) 10px); /* 6-7% visible */
        transform: translate(10px, -20px);
    }
    80% {
        clip-path: inset(5px calc(100% - 91%) calc(100% - 90%) 0px); /* 9-10% visible */
        transform: translate(-15px, 25px);
    }
    100% {
        clip-path: inset(0px calc(100% - 98%) calc(100% - 97%) 0px); /* 2-3% visible */
        transform: translate(5px, -10px);
    }
}

@keyframes glitch-anim2 {
    0% {
        clip-path: inset(5% calc(100% - 95%) calc(100% - 95%) 0%); /* 5% visible */
        transform: translate(0px, 0px);
    }
    20% {
        clip-path: inset(8% calc(100% - 92%) calc(100% - 90%) 5%); /* 8-10% visible */
        transform: translate(-25px, 20px);
    }
    40% {
        clip-path: inset(6% calc(100% - 97%) calc(100% - 96%) 10%); /* 3-4% visible */
        transform: translate(30px, -25px);
    }
    60% {
        clip-path: inset(10% calc(100% - 94%) calc(100% - 93%) 2%); /* 6-7% visible */
        transform: translate(-20px, 15px);
    }
    80% {
        clip-path: inset(7% calc(100% - 91%) calc(100% - 90%) 0%); /* 9-10% visible */
        transform: translate(20px, -30px);
    }
    100% {
        clip-path: inset(5% calc(100% - 98%) calc(100% - 97%) 0%); /* 2-3% visible */
        transform: translate(-10px, 10px);
    }
}


