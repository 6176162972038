.control-icon {
  color: #fff;
}
.control-icon:disabled {
  color: #7a7a7a;
}

.video-loading {
  position: absolute;
  display: flex;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loading-icon {
  font-size: 50px;
  color: #fff;
}

.volume-container {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.volume-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.volume-slider {
  position: absolute;
  top: 0px; /* Ajusta según la posición deseada */
  left: -35px;
  transform: translateX(5px);
  transform-origin: bottom;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 1;
  background-color: #35353559;
  border-radius: 10px;
}

.volume-slider.active {
  transform: translateX(-40%);
  opacity: 1;
}

.volume-slider input[type="range"] {
  -webkit-appearance: none; /* Elimina el estilo predeterminado en Chrome, Safari, Opera */
  appearance: none;
  width: 60px; /* Especifica un ancho */
  height: 2px; /* Ajusta según tus necesidades */
  background: #d3d3d3; /* Color de fondo */
  outline: none; /* Elimina el borde de foco */
  opacity: 0.7; /* Opacidad */
  transition: opacity 0.2s; /* Transición suave */
}

.volume-slider input[type="range"]:hover {
  opacity: 1; /* Opacidad al pasar el ratón por encima */
}

.video-container-v {
  position: relative;
  width: 100%; /* El ancho que desees, o 100% para que sea responsivo */
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 por 16 = 0.5625) */
  background-color: #333; /* Fondo negro para cuando el video aún no carga */
  border-radius: 11px;
}

.hidden {
  visibility: hidden;
}

.video-container-v video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container-v .portada {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen para cubrir el contenedor, puede recortarla */
}

.video-timeline-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  position: absolute;

  z-index: 2;
}

.video-timeline-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  visibility: hidden;
}

.video-timeline-slider::-moz-range-thumb {
  visibility: hidden;
}

.div-block-12 {
  cursor: pointer;
}
