:root {
  --white: white;
  --black: black;
  --verde: #a5ff32;
}

body {
  color: #333;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.section-header-home {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  background-color: #fff0;
  background-image: linear-gradient(to top, #000, #0000 12%), url('../images/img-header.jpg');
  background-position: 0 0, 50%;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 10% 50px;
  display: flex;
}

.footer {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-image: linear-gradient(#000, #fff0 12%), url('../images/SECTION--Footer.png');
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  min-height: 240px;
  display: flex;
}

.vectors-wrapper {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.contenido-categorias {
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(280px, 280px));
  grid-auto-columns: minmax(280px, 280px);
  place-content: center;
  place-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: grid;
}

.categoria {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  background-color: #323644;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  height: 100px;
  padding: 10px;
  display: flex;
}

.vectors-wrapper-2 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.div-categoria {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 190px;
  height: 80px;
  display: flex;
}

.categoria-titullo {
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
}

.categoria-num-cursos {
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}

.header {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.header-menu-2 {
  grid-column-gap: 50px;
  grid-row-gap: 0px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.menu-2 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  order: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.item-menu-selected {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 0 solid #fff;
  border-bottom-width: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.valor-2 {
  color: #f1f7ff;
  text-shadow: 0 4px 4px #00000040;
  font-size: 16px;
  font-weight: 600;
}

.item-menu {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 5px #924d4d;
  border-bottom: 5px solid #00000003;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.botones-inicio-sesion-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.bienvenida {
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.eslogan {
  grid-column-gap: 27px;
  grid-row-gap: 27px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.mega-text {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 101%;
}

.text-3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 101%;
}

.botones-inicio {
  grid-column-gap: 33px;
  grid-row-gap: 33px;
  flex-flow: row;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.btn1 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #333;
  cursor: pointer;
  background-color: #fff;
  border: 1px #000;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 10px 20px;
  display: flex;
}

.btn1-text {
  font-size: 16px;
  font-weight: 400;
}

.btn2 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.btn2-text {
  font-size: 16px;
  font-weight: 400;
}

.frame-certificacion {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-3 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  display: flex;
}

.certificate {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 60px;
  display: flex;
}

.text-4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 101%;
}

.text-5 {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 101%;
}

.barra-busqueda {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 6px 0;
  display: flex;
}

.barra-de-busqueda---vista-alumno-2 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  object-fit: fill;
  background-color: #d9d9d9;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  height: 30px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
}

.vectors-wrapper-5 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.text-10 {
  color: #707585;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 112.5%;
}

.section-home {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  background-color: #000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Poppins, sans-serif;
  display: flex;
}

.section-group {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.section-title {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-6 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.section-text {
  color: #fff;
  width: 100%;
  font-size: 24px;
  font-weight: 600;
}

.cursos {
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(280px, 280px));
  grid-auto-columns: minmax(280px, 280fr);
  grid-auto-flow: row;
  place-content: center;
  place-items: center;
  width: 100%;
  display: grid;
}

.curso {
  grid-column-gap: 13px;
  grid-row-gap: 10px;
  background-color: #323644;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  height: 330px;
  padding-bottom: 0;
  display: flex;
}

.section-tutle-pagination-courses {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.pagination {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
}

._1 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.vectors-wrapper-22 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.text-22 {
  color: #fff;
  text-align: right;
  min-width: 120px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
}

.valor-8 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #fff;
  border: 2px solid #323644;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
}

.text-23 {
  color: #323644;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.valor-9 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
}

.frame-hora-y-lecciones-3 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.section-courses {
  background-color: #000;
  font-family: Poppins, sans-serif;
}

.section-text-footer {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.section-header-course-no-purchased {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  background-color: #fff0;
  background-image: linear-gradient(0deg, #000, #0000 18%), url('../images/img-header-courses.jpg');
  background-position: 0 0, 50%;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 10px 10% 50px;
  display: flex;
}

.section-cnc {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 92px);
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  position: relative;
}

.section-info2-cnc {
  clear: left;
  background-color: #323644cc;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  height: 426px;
  display: flex;
  position: sticky;
  top: 10px;
  overflow: hidden;
}

.frame-imagen-curso {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  object-fit: cover;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 145px;
  min-height: 145px;
  max-height: 145px;
  display: flex;
}

.frame-1 {
  grid-column-gap: 22px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1px;
  text-decoration: none;
  display: flex;
}

.frame-precio {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.icon-heart-cnc-card {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.frame-2 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 123px;
  padding: 1px;
  text-decoration: none;
  display: flex;
}

.frame-tiempo {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.icon-cnc-card {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.botones {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.section-info-cnc {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.section-cnc-header {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
}

.text-cnc-title {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
}

.text-cnc-descripcion {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}

.div-cnc-informativo {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 23px;
  display: flex;
}

.icon-cnc-informativo {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.text-cnc-informativo {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
}

.div-cnc-informativo2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.section-cnc-aprenderas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #323644cc;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 850px;
  padding: 15px;
  display: flex;
}

.div-cnc-subtitulo {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.icon-cnc-title {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.title-cnc-section {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
}

.div-cnc-puntos-aprendizaje {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template: "Area"
                 "."
                 "."
                 / auto auto;
  grid-auto-columns: minmax(auto, auto);
  justify-content: center;
  place-items: start end;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  display: grid;
}

.div-cnc-puntos-aprenderas {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.icon-cnc-aprenderas {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 11.25px;
  height: 15px;
  display: flex;
}

.text-cnc-aprenderas {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.section-cnc-contenido {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.tabla {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.fila-1 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 23px;
  display: flex;
}

.lecci-n {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.titulo-lecci-n {
  grid-column-gap: 17px;
  grid-row-gap: 17px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-37 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.text-cnc-contenido-tiempo {
  color: #fff;
  text-align: right;
  width: 200px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.fila-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px solid #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 23px;
  display: flex;
}

.section-cnc-instructor {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.intructor {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.profesor-info-cnc {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  min-width: 35%;
  max-width: 50%;
  display: flex;
}

.div-info-profesor-cnc {
  grid-column-gap: 11px;
  grid-row-gap: 11px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.div-profesor-1-cnc-a {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.nombre-profesor-cnc {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 119%;
}

.profesion-profesor-cnc {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.div-profesor-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 93px));
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: grid;
}

.calificacion-4 {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.vectors-wrapper-39 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  display: flex;
}

.calificacion-profesor-cnc {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 119%;
}

.vectors-wrapper-40 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.frame-17 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.section-cnc-comentarios {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  display: flex;
  position: relative;
}

.comentarios-contenido {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-rows: auto;
  grid-template-columns: 45% 45%;
  grid-auto-columns: 50%;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: grid;
  position: relative;
}

.comentario-cnc {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.text-comentario-nombre {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 119%;
}

.valoracion-comentario-cnc {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.calificacion-comentario-cnc {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.vectors-wrapper-43 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  display: flex;
}

.tiempo-comentario-cnc {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 119%;
}

.text-comentario {
  color: #fff;
  text-align: justify;
  word-break: break-all;
  overflow-wrap: break-word;
  object-fit: fill;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  position: relative;
  overflow: visible;
}

.boton {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.texto-3 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.section-course-no-comprado {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: #000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  min-height: 100px;
  max-height: 100%;
  padding-bottom: 40px;
  font-family: Poppins, sans-serif;
  display: flex;
}

.section-cnc-perfil-participante {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-cnc-general {
  color: #fff;
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.section-cnc-requerimientos {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.section-about-us {
  background-color: #000;
  font-family: Poppins, sans-serif;
}

.text-cnc-card {
  color: #fff;
  text-align: justify;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
}

.price-cnc-card {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
}

.div-v-line-cnc {
  background-color: #fff;
  border-radius: 5px;
  width: 5px;
  height: 75px;
}

.md-contactanos {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex: 0 auto;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(96px, 50%));
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 980px;
  display: grid;
}

.div-md-contactanos-der {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #272a37;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 625px;
  height: 700px;
  padding: 119px 40px;
  display: flex;
}

.form-md {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.inputs-md {
  grid-column-gap: 27px;
  grid-row-gap: 27px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.div-input-row {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  place-content: start flex-start;
  place-items: center;
  width: 100%;
  display: grid;
}

.input-md {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.text-input-md {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.value-input-md {
  color: #fff;
  border: 0 #000;
  border-bottom: 4px solid #707585;
  border-radius: 0;
  width: 100%;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  transition: border-color .2s cubic-bezier(.55, .085, .68, .53);
}

.value-input-md:hover {
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.value-input-md:focus {
  border-bottom-color: #fff;
}

.input-2 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 60px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.textarea-md {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 129px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.butons-md {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(140px, 140px));
  grid-auto-columns: 1fr;
  justify-content: end;
  place-items: flex-end end;
  width: 100%;
  display: grid;
}

.btn1-md {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  display: flex;
}

.btn2-md {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  display: flex;
}

.text-btn2-md {
  color: #323644;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.div-md-contactanos-izq {
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  background-image: url('../images/img-contacto.webp');
  background-position: 0 0;
  background-size: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 625px;
  height: 700px;
  padding: 70px 44px;
  display: flex;
}

.div-title-md {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 537px;
  display: flex;
}

.title-md {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: 700;
}

.text-md {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.contacto-md {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-contacto-md {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.text-contacto-md {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.body {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  display: flex;
}

.text-btn1-md {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.md-login {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex: 0 auto;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(96px, 50%));
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 980px;
  display: grid;
  position: static;
}

.div-md-login-der {
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  background-image: url('../images/img-login.webp');
  background-position: 0 0;
  background-size: cover;
  border-radius: 0 20px 20px 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  max-width: 625px;
  height: 700px;
  padding: 70px 44px;
  display: flex;
}

.div-md-login-izq {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  background-color: #272a37;
  border-radius: 20px 0 0 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 625px;
  height: 700px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.subtitle-md {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.cita-md {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-comillas-md {
  color: #fff;
  font-family: Dhurjati, sans-serif;
  font-size: 70px;
  font-weight: 400;
  position: relative;
  top: 15%;
}

.text-cita-md {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.autor-cita-md {
  color: #fff;
  text-align: right;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.text2-md {
  color: #1d90f5;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.text1-md {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.div-md-more-text {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(190px, 190px));
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.md-signup {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex: 0 auto;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(96px, 50%));
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 980px;
  display: grid;
  position: static;
}

.div-md-signup-izq {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  background-color: #272a37;
  border-radius: 20px 0 0 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 625px;
  height: 700px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.div-md-signup-der {
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  background-image: url('../images/img-singup.webp');
  background-position: 0 0;
  background-size: cover;
  border-radius: 0 20px 20px 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  max-width: 625px;
  height: 700px;
  padding: 70px 44px;
  display: flex;
}

.frase-2 {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  display: flex;
}

.text-31 {
  color: #fff;
  width: 884px;
  height: auto;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 44px;
}

.frame-21 {
  grid-column-gap: 27px;
  grid-row-gap: 27px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  height: 300px;
  display: flex;
}

.text-32 {
  color: #fff;
  text-align: center;
  width: 227px;
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 112.5%;
}

.vectors-wrapper-45 {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 80%;
  display: flex;
}

.frame-22 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.text-33 {
  color: #fff;
  text-align: center;
  height: auto;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 184.5%;
}

.error-219fdc8b-a845-1607-b55a-8184c0292da2 {
  color: #fff;
  text-align: center;
  height: auto;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 179.5%;
}

.vemos-nosotros-mismos {
  grid-column-gap: 37px;
  grid-row-gap: 37px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  display: flex;
}

.frame-23 {
  grid-column-gap: 20%;
  grid-row-gap: 20%;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.frame-24 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 640px;
  height: 270px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.camaraderia-1 {
  object-fit: cover;
}

.text-35 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 112.5%;
}

.frame-25 {
  grid-column-gap: 19px;
  grid-row-gap: 19px;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 640px;
  height: 270px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.en-palabras-simples-somos-los-mejores-en-lo-que-hacemos {
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  line-height: 112.5%;
}

.en-palabras-simples-somos-los-mejores-en-lo-que-hacemos-0 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 112.5%;
}

.en-palabras-simples-somos-los-mejores-en-lo-que-hacemos-1 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 112.5%;
}

.frame-29 {
  grid-column-gap: 10%;
  grid-row-gap: 10%;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 320px;
  display: flex;
}

.text-36 {
  color: #fff;
  text-align: center;
  height: auto;
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 112.5%;
}

.text-37 {
  color: #fff;
  text-align: center;
  width: 625px;
  height: auto;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 182.5%;
}

.section-objetivo {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex: 0 auto;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(50%, 50%));
  grid-auto-columns: minmax(50%, 100%);
  justify-content: center;
  place-items: center;
  width: 100%;
  height: auto;
  display: grid;
}

.image-9, .image-10 {
  width: 100%;
  height: 100%;
}

.div-pilar-sociedad {
  width: 100%;
}

.menu-3 {
  z-index: 1;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: #323644;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: max-width .2s, min-width .2s, width .2s;
  display: flex;
  position: relative;
}

.image-1 {
  object-fit: cover;
  display: none;
}

.menu-lateral {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.menu-item {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.menu-item:hover {
  background-color: #d9d9d91a;
}

.vectors-wrapper-47 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.item {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  transition: opacity 2s;
  display: none;
}

.frame-14 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  display: none;
}

.cuadro-imagen {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
  position: relative;
}

.vectors-wrapper-48 {
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.imagen {
  object-fit: cover;
  position: absolute;
  top: 97px;
}

.texto {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: #323644;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 203px;
  height: 70px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 101%;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 16px;
  left: 14px;
}

.text-38 {
  color: #fff;
  text-align: right;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 119%;
}

.body-2 {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: auto;
}

.header-2 {
  z-index: 2;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-image: linear-gradient(#1d1d1d, #1d1d1d);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-top: 10px;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  position: sticky;
  top: 0;
}

.vectors-wrapper-50 {
  cursor: pointer;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.body-3 {
  background-image: linear-gradient(#1d1d1d, #1d1d1d);
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
}

.frame-30 {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-left: 70px;
  padding-right: 70px;
  font-family: Poppins, sans-serif;
  display: flex;
  position: relative;
}

.temario---foro {
  z-index: 1;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-self: auto;
  align-items: flex-start;
  width: 470px;
  display: flex;
  position: sticky;
  top: 60px;
}

.botones-2 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 468px;
  display: flex;
}

.temario {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  background-color: #32364461;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 11px 58px;
  display: flex;
}

.vectors-wrapper-52 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.text-39 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.foro {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  background-color: #32364461;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 11px 65px;
  display: flex;
}

.contenido-tem-for {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #323644;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  display: flex;
  overflow: visible;
}

.modulo {
  z-index: 1;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #707585;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 16px;
  display: flex;
  position: sticky;
  top: 0;
}

.text-40 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.text-41 {
  color: #fff;
  text-align: right;
  width: 100px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
}

.temas {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.item_modulo_temario {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: #707585;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.status-temario {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  display: flex;
}

.vectors-wrapper-53 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.conjunto_temario {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.titulo_temario {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
  cursor: pointer;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.vectors-wrapper-55 {
  -webkit-text-stroke-color: #707585;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.text-42 {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.tema_temario {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 382px;
  padding-left: 30px;
  display: flex;
}

.item_temario {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-43 {
  color: #b1bdcd;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.vectors-wrapper-56 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  display: flex;
}

.historia-evaluaci-n-1 {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.status-temario-3 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  display: flex;
}

.vectors-wrapper-58 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 100%;
  display: flex;
}

.vectors-wrapper-67 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  max-width: 24px;
  height: 24px;
  display: flex;
}

.vectors-wrapper-linea {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 100%;
  display: flex;
}

.status-temario-9 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 124px;
  display: flex;
}

.vectors-wrapper-71 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 100%;
  display: flex;
}

.div-block-2 {
  width: 100%;
  height: auto;
  overflow: visible;
}

.frame-48 {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.frame-45 {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-51 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-weight: 500;
}

.frame-44 {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-52 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 119%;
}

.frame-47 {
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.frame-46 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: 279px;
  display: flex;
}

.vectors-wrapper-72 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 28px;
  display: flex;
}

.text-53 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.text-54 {
  color: #707585;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.boton-evaluacion {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 35.8633px;
  padding: 4px 10px 4px 20px;
  display: flex;
  position: static;
  top: 7.9696px;
  left: 791.26px;
}

.text-55 {
  text-align: center;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.div-block-3 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.frame-128 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: auto;
  display: flex;
}

.text-56 {
  color: #707585;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.vectors-wrapper-74 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.contenido-del-curso {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  color: #1de8f5;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.info-y-recursos {
  grid-column-gap: 53px;
  grid-row-gap: 53px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 30px;
  display: flex;
}

.item-menu-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

.valor {
  color: #f1f7ff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.content-info-curso-recursos {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-right: 10%;
  display: flex;
  position: relative;
}

.lo-que-aprenderas {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  border: 1px solid #707585;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  display: flex;
}

.text-57 {
  color: #fff;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.descripcion {
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-58 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.error-fd47e368-1448-67c0-bfda-a1db6f9e2066 {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.bienvenidos-al-curso-fundamentos-de-derivados-futuros-forwards-opciones-y-swaps-este-curso-es-perfecto-para-aquellos-que-desean-adquirir-habilidades-de-derivados-financieros-aprender-s-a-navegar-los-mercados-regulados-y-no-regulados-y-a-utilizar-los-forward-para-planificar-tu-estrategia-de-inversi-n-a-largo-plazo-profundizaremos-en-los-futuros-financieros-y-entender-s-c-mo-funcionan-los-swaps-y-c-mo-pueden-ayudarte-a-diversificar-tus-inversiones-y-reducir-el-riesgo-0 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.bienvenidos-al-curso-fundamentos-de-derivados-futuros-forwards-opciones-y-swaps-este-curso-es-perfecto-para-aquellos-que-desean-adquirir-habilidades-de-derivados-financieros-aprender-s-a-navegar-los-mercados-regulados-y-no-regulados-y-a-utilizar-los-forward-para-planificar-tu-estrategia-de-inversi-n-a-largo-plazo-profundizaremos-en-los-futuros-financieros-y-entender-s-c-mo-funcionan-los-swaps-y-c-mo-pueden-ayudarte-a-diversificar-tus-inversiones-y-reducir-el-riesgo-1 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.requerimientos {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.text-59 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.vectors-wrapper-76 {
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 18px;
  display: flex;
}

.instructor {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-60 {
  color: #fff;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.text-61 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 119%;
}

.frame-13 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 141px;
  display: flex;
}

.calificacion {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  justify-content: flex-start;
  align-items: center;
  width: 47px;
  display: flex;
}

.vectors-wrapper-77 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  display: flex;
}

.cursos-2 {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  justify-content: flex-start;
  align-items: center;
  width: 90px;
  display: flex;
}

.descripcion-profe {
  color: #fff;
  text-align: justify;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.div-block-5 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: 49% 49%;
  grid-auto-columns: 1fr;
  width: 100%;
  height: auto;
  display: grid;
}

.frame-129 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.vectors-wrapper-78 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 10.7347px;
  height: 12px;
  display: flex;
}

.text-62 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.div-block-6 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
  width: 100%;
  display: flex;
}

.div-block-7 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  height: 60px;
  display: flex;
}

.titulo_blocked_temario {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.tema_blocked_temario {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 382px;
  padding-left: 30px;
  display: flex;
}

.item_blocked_temario {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.punto_blocked_temario {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.item_text_blocked_temario {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.curso-alumno-video {
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  width: 100%;
  height: auto;
  position: relative;
}

.div-block-9 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.text-hora-estudio {
  color: #fff;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 119%;
}

.text-hora-estudio-2 {
  color: #fff;
  text-align: right;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.text-perfil {
  color: #1d1d1d;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.div-block-10 {
  cursor: pointer;
  background-image: linear-gradient(#9747ff, #9747ff);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
}

.menu-item-seleccionado {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-image: linear-gradient(#d9d9d91a, #d9d9d91a);
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.leccion-1 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  display: flex;
}

.vectors-wrapper-79 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.text-63 {
  color: #fff;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.duracioon {
  color: #fff;
  text-align: right;
  width: 150px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 119%;
}

.div-block-11 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px 20px 50px;
  display: flex;
}

.section-cnc-a {
  z-index: 0;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  position: relative;
}

.video {
  background-color: #363636;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.controles-container {
  background-color: #00000042;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 5px 10px 20px;
  display: flex;
  position: absolute;
  inset: 0% auto auto 0%;
}

.video-arriba {
  justify-content: flex-end;
  height: 20px;
  display: flex;
}

.video-centro {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-12 {
  background-image: linear-gradient(#ffffffb3, #ffffffb3);
  border-radius: 10px;
  width: 100%;
  height: 7px;
  position: relative;
}

.div-block-13 {
  justify-content: space-between;
  width: 100%;
  height: 30px;
  display: flex;
}

.div-block-15 {
  justify-content: space-between;
  align-items: center;
  width: 50px;
  height: 100%;
  display: flex;
}

.div-block-17 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
}

.text-block {
  color: #fff;
  width: auto;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.text-block-2 {
  color: #ffffff80;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.preguntas-foro {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  width: 100%;
  padding-right: 10px;
  display: flex;
  position: relative;
  overflow: auto;
}

.perfil-circulo-foro {
  background-image: linear-gradient(#32ffa9, #32ffa9);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
}

.div-block-19 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: stretch;
  display: flex;
}

.div-block-20 {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

.text-block-3 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.div-block-21 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.elipce-for {
  background-image: linear-gradient(#707585, #707585);
  border-radius: 10px;
  width: 3px;
  height: 3px;
}

.sub-text-for-pregunta {
  color: #707585;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-weight: 400;
}

.respeusta-foro-text {
  color: #fff;
  text-align: justify;
  margin-left: 60px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.respuesta-foro {
  margin-left: 15%;
  position: relative;
}

.image-12 {
  width: 20px;
}

.vectors-wrapper-81 {
  cursor: pointer;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18.9353px;
  height: 17.1366px;
  display: flex;
}

.div-block-23 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #323644;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  top: auto;
  bottom: 0%;
}

.div-block-24 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  cursor: pointer;
  background-image: linear-gradient(#707585, #707585);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.pregunta-foro {
  position: relative;
}

.div-block-25 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
  display: flex;
}

.div-block-26 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.text-block-4 {
  color: #fff;
}

.vectors-wrapper-82 {
  cursor: pointer;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 14.6167px;
  display: flex;
}

.icon-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px;
  display: flex;
}

.icon-2.fullscreen {
  cursor: pointer;
}

.vectors-wrapper-83 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 11.6667px;
  height: 11.6667px;
  display: flex;
}

.vectors-wrapper-84 {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.frame-130 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
  background-color: #0000004d;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 108px;
  display: flex;
}

.vectors-wrapper-86 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 42px;
  display: flex;
}

.icon-10 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  background-image: linear-gradient(#0003, #0003);
  border-radius: 100%;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  position: relative;
}

.vectors-wrapper-87 {
  justify-content: center;
  align-items: center;
  display: none;
}

.icon-10-text {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
}

.vectors-wrapper-88 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.boton-evaluacion-activo {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: #1d1d1d;
  cursor: pointer;
  background-image: linear-gradient(#fff, #fff);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 35.8633px;
  padding: 4px 10px 4px 20px;
  display: flex;
  position: static;
  top: 7.9696px;
  left: 791.26px;
}

.perfil-circulo-instructor {
  background-image: linear-gradient(#32ffa9, #32ffa9);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  display: flex;
}

.text-perfil-instructor {
  color: #1d1d1d;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.perfil-circulo-subtitulo {
  background-image: linear-gradient(#32ffa9, #32ffa9);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 10px;
  display: flex;
}

.text-perfil-subtitulo {
  color: #1d1d1d;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-weight: 600;
}

.text-65 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.text-66 {
  color: #fff;
  text-align: right;
  width: 100px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
}

.vectors-wrapper-89 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  display: flex;
}

.text-67 {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.vectors-wrapper-90 {
  -webkit-text-stroke-color: #707585;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.text-68 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.text-69 {
  color: #fff;
  text-align: right;
  width: 100px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
}

.vectors-wrapper-93 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  display: flex;
}

.text-70 {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.vectors-wrapper-94 {
  -webkit-text-stroke-color: #707585;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.cuestionario-pregunta {
  background-color: #323644;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.cuestionario-pgt {
  grid-column-gap: 5vw;
  grid-row-gap: 5vw;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.cuestionario-bar-progress {
  height: 10px;
}

.cuestionario-num-pgt {
  height: 25px;
}

.cuestionario-pgt-izq, .cuestionario-pgt-der {
  justify-content: center;
  align-items: center;
  width: 50px;
  display: flex;
}

.cuestionario-pgt-cen {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.image-13 {
  cursor: pointer;
  transform-style: preserve-3d;
  transform: rotateX(0)rotateY(180deg)rotateZ(0);
}

.cuestionario-pgt-pregunta, .cuestionario-pgt-respuesta {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.cuestionario-pgt-preguntas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  display: flex;
}

.cuestionario-num-pregunta-text {
  color: #b1bdcd;
  text-align: right;
  font-family: Poppins, sans-serif;
  font-weight: 300;
}

.cuestionario-contenido {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-color: #323644;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  position: relative;
  inset: 0% 0% auto;
}

.cuestionario-info {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.text-block-5 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 40px;
  font-weight: 700;
}

.text-block-6 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.div-block-27 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  display: flex;
}

.cuestionario-final-caja {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-image: linear-gradient(#707585, #707585);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 60px;
  padding: 8px 20px;
  display: flex;
}

.cuestionario-caja-text-num {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.cuestionario-caja-text {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.div-block-28 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.section-header-nosotros {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  background-color: #fff0;
  background-image: linear-gradient(to top, #000, #0000 18%), url('../images/img-contacto.webp');
  background-position: 0 0, 50%;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 10px 10% 50px;
  display: flex;
}

.section-header-courses {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  background-color: #fff0;
  background-image: linear-gradient(0deg, #000, #0000 18%), url('../images/img-header-courses.jpg');
  background-position: 0 0, 50%;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 10px 10% 50px;
  display: flex;
}

.section-cnc-perfil-egreso {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-cnc-intructor {
  color: #fff;
  width: 100%;
  max-width: 100%;
  height: auto;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
  display: block;
  position: relative;
  overflow: hidden;
}

.nombre-instructor {
  color: #fff;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.header-icon-perfil {
  background-image: linear-gradient(#9747ff, #9747ff);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
}

.temario-contenido {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: auto;
}

.image-14 {
  cursor: pointer;
}

.frame-51 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: minmax(100px, 300px) minmax(100px, 300px);
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 562px;
  display: grid;
}

.btn-video-recurso {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #1de8f5;
  border: 1px solid #1de8f5;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 14px;
  font-family: Poppins, sans-serif;
  display: flex;
}

.vectors-wrapper-96 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.btn-text-recursos {
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.vectors-wrapper-97 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.content-recursos {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-right: 10%;
  display: flex;
  position: relative;
}

.list-glosario-grafico {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 125px;
  grid-auto-columns: 125px;
  grid-auto-flow: column;
  font-size: 12px;
  display: flex;
}

.item-glosario-grafico-infografia {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #ff8d8d;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.btn-infografia-recursos {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #ff8d8d;
  cursor: pointer;
  border: 1px solid #ff8d8d;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  display: flex;
}

.vectors-wrapper-100 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.vectors-wrapper-101 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.btn-precentacion-recursos {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #a5ff32;
  cursor: pointer;
  border: 1px solid #a5ff32;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 9px 15px;
  display: flex;
}

.vectors-wrapper-102 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.vectors-wrapper-103 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.btn-plantilla-recursos {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #5ae7c5;
  cursor: pointer;
  border: 1px solid #5ae7c5;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px;
  display: flex;
}

.vectors-wrapper-104 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.vectors-wrapper-105 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.item-glosario-grafico-video {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.text-glosario-grafico {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.content-glosario-grafico {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  display: flex;
}

.item-glosario-grafico-precentacion {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #a5ff32;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.item-glosario-grafico-plantilla {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #5ae7c5;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.content-recursos-r {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  display: flex;
}

.btn-text-glosario {
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.vectors-wrapper-106 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 20px;
  display: flex;
}

.text-num-mis-cursos {
  text-align: center;
  align-self: center;
  width: auto;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.circulo-num-mis-cursos {
  color: #b1bdcd;
  background-image: linear-gradient(#323644, #323644);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 30px;
  padding: 5px 10px;
  display: flex;
}

.div-block-30 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.text-titulo-mis-cursos {
  color: #fff;
  text-align: left;
  align-self: auto;
  width: auto;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.div-block-31 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  width: 70%;
  height: 100%;
  display: flex;
}

.div-block-32 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  width: 530px;
  height: 100%;
  display: flex;
}

.tcm-contenedor {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 400px);
  grid-auto-columns: 400px;
  grid-auto-flow: row;
  justify-content: center;
  justify-items: stretch;
  width: 100%;
  height: auto;
  display: grid;
}

.tcm-seleccionado {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  color: #fff;
  cursor: pointer;
  background-color: #32364461;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 400px;
  min-width: 370px;
  max-width: 400px;
  height: 240px;
  padding: 20px 27px;
  display: flex;
}

.interior {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
}

.titulos {
  grid-column-gap: 11px;
  grid-row-gap: 11px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 363px;
  display: flex;
}

.tmc-titulo {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
}

.tcm-profesor {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.tcm-vigencia-hrs-restantes-contenedor {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.tcm-vigencia-hrs-restrantes-datos {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-107 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.tcm-viigencia-activa {
  color: #a5ff32;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.tcm-hrs-restantes {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.tcm-sub-datos {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tcm-sub-datos-text {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.vectors-wrapper-108 {
  object-fit: cover;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 20px;
  height: 19px;
  display: flex;
}

.barra-progreso {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.vectors-wrapper-109 {
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 5px;
  display: flex;
}

.tcm-porcentaje-text {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 119%;
}

.tcm {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  color: #b1bdcd;
  cursor: pointer;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 400px;
  min-width: 370px;
  max-width: 400px;
  height: 240px;
  padding: 20px 27px;
  display: flex;
}

.tc {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.vectors-wrapper-110 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.tcm-vigencia-por-vencer {
  color: #ff3a3a;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.vectors-wrapper-111 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 19px;
  display: flex;
}

.vectors-wrapper-112 {
  object-fit: cover;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 5px;
  display: flex;
}

.tcm-sub-datos-contenedor {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.text-block-7 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.div-block-33 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  display: flex;
}

.div-block-35 {
  background-image: linear-gradient(#464d63, #464d63);
  border-radius: 8px;
  align-items: flex-start;
  width: 100%;
  height: 120px;
  padding: 10px;
  display: flex;
}

.div-block-34 {
  background-image: linear-gradient(#464d63, #464d63);
  border-radius: 8px;
  align-items: flex-start;
  width: 100%;
  height: 35px;
  padding: 10px;
  display: flex;
}

.div-block-36 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  display: flex;
}

.div-block-37 {
  background-image: linear-gradient(#323644, #323644);
  border-radius: 20px;
  flex-direction: column;
  display: flex;
}

.div-block-38 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  color: #fff;
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Poppins, sans-serif;
  display: flex;
}

.div-block-39 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-image: linear-gradient(#515870, #515870);
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px;
  display: flex;
}

.div-block-40 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  display: flex;
}

.div-block-41 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div-block-42 {
  justify-content: flex-end;
  display: flex;
}

.text-block-8 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.text-block-9 {
  color: #707585;
  font-size: 12px;
}

.text-block-10 {
  font-size: 12px;
  font-weight: 300;
}

.div-block-43 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  display: flex;
}

.text-block-11 {
  color: #fff;
  font-size: 12px;
  font-style: italic;
}

.div-block-44 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-12 {
  color: #fff;
  font-size: 12px;
  font-style: italic;
}

.image-15 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.text-block-13 {
  color: #707585;
  font-size: 12px;
  font-style: italic;
}

.text-block-14 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.text-block-15 {
  color: #707585;
  font-size: 12px;
}

.div-block-45 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.text-block-17 {
  color: #707585;
  font-size: 12px;
}

.vectors-wrapper-113 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 15.75px;
  display: flex;
}

.div-block-46 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  display: flex;
}

.div-block-47 {
  cursor: not-allowed;
  background-image: linear-gradient(#464d63, #464d63);
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.div-block-48 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: space-around;
  display: flex;
}

.text-block-18 {
  color: #707585;
  font-family: Poppins, sans-serif;
  font-size: 12px;
}

.div-block-49 {
  cursor: not-allowed;
  background-image: linear-gradient(#464d63, #464d63);
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.div-block-50 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.text-block-19 {
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.text-block-20 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.div-block-51 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-block-21 {
  color: #fff;
  cursor: text;
  background-image: linear-gradient(#0000, #0000);
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.text-block-22 {
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.vectors-wrapper-114 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  display: flex;
}

.contenido-a-perfil {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  position: static;
}

.contenido-informacion---mi-perfi {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
}

.text-83 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.frame-8 {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.frame-7 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.busqueda-filtro {
  grid-column-gap: 67px;
  grid-row-gap: 67px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 1079px;
  padding: 22px 2px;
  display: flex;
}

.barra-de-busqueda-perfil {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-color: #fff;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 25px;
  padding: 5px 9px;
  display: flex;
}

.vectors-wrapper-115 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.input-busqueda {
  color: #707585;
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 112.5%;
}

.contenido {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.cursos-3 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 280px;
  height: auto;
  display: flex;
  position: relative;
}

.curso-perfil {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding: 14px 28px;
  display: flex;
  position: relative;
}

.contenido-curso-perfil {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.frame-131 {
  grid-column-gap: 13px;
  grid-row-gap: 13px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-116 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  display: flex;
}

.text-86 {
  color: #fff;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 119%;
}

.text-87 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 119%;
}

.contenido-2 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: auto;
}

.contenido-curso {
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
}

.frame-33 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-90 {
  color: #fff;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 119%;
}

.frame-132 {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  cursor: pointer;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-117 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.text-91 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.vectors-wrapper-118 {
  border: 1px dashed #b1bdcd;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.text-92 {
  color: #848282;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.lateral {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  display: flex;
  position: sticky;
  top: 10px;
}

.modulos {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #494f63;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  padding: 16px 31px;
  display: flex;
}

.frame-26 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-119 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  display: flex;
}

.frame-133 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
}

.text-93 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 119%;
}

.frame-134 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.frame-135 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
}

.certificado {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px dashed #4d546c;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  padding: 16px 20px;
  display: flex;
}

.frame-43 {
  grid-column-gap: 17px;
  grid-row-gap: 17px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-94 {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 119%;
}

.vectors-wrapper-120 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  display: flex;
}

.boton-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px solid #575b75;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px 26px;
  display: flex;
}

.text-95 {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
}

.frame-6 {
  grid-column-gap: 45px;
  grid-row-gap: 45px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 320px;
  display: none;
}

.info-estudiante {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  border: 1px solid #fff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  padding: 10px 10px 10px 20px;
  display: flex;
}

.text-96 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
}

.text-97 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.vectors-wrapper-121 {
  border: 1px dashed #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  display: flex;
}

.tu-progreso {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 490.443px;
  display: flex;
}

.titulo {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 300px;
  display: flex;
}

.text-98 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
}

.contenido-3 {
  grid-column-gap: 74px;
  grid-row-gap: 74px;
  background-image: linear-gradient(#323644, #32364400 80%);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 436px;
  min-height: 350px;
  max-height: 435px;
  padding-top: 23px;
  padding-bottom: 23px;
  display: flex;
}

.dato-1 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.frame-136 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lecciones-completadas {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
}

.text-100 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 119%;
}

.div-block-54 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  display: flex;
}

.div-block-55 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.frame-137 {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.contenido-4 {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.dashboard {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70%;
  height: 100%;
  display: flex;
}

.progreso-cursos {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.proogreso-curso-header {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.titulo-2 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.text-101 {
  color: #fff;
  width: auto;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
}

.frame-138 {
  background-color: #323644;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.text-102 {
  color: #b1bdcd;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
}

.flechas {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-122 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.progreso-curso-cursos {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 150px;
  padding-bottom: 10px;
  display: flex;
  overflow: auto;
}

.progreso-curso-curso {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  background-color: #323644;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 294px;
  height: 122px;
  padding: 14px 9px;
  display: flex;
}

.progreso-curso-curso:hover {
  color: #707585;
  background-color: #32364461;
}

.info {
  grid-column-gap: 13px;
  grid-row-gap: 13px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 94px;
  display: flex;
}

.loader {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.info-2 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 174px;
  height: 94px;
  display: flex;
}

.text-104 {
  letter-spacing: -.02em;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 98%;
}

.text-105 {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 98%;
}

.barra-video {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.vectors-wrapper-124 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 8px;
  display: flex;
}

.vectors-wrapper-125 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 8px;
  display: flex;
  position: absolute;
  top: 0;
  left: -1px;
}

.minutos {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.avance-curso-gral {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
}

.titulo-3 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.frame-139 {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
}

.tarjeta-curso {
  color: #fff;
  cursor: pointer;
  background-color: #323644;
  border-radius: 10px;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1042px;
  height: 130px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  overflow: hidden;
}

.tarjeta-curso:hover {
  color: #707585;
  background-color: #32364461;
}

.graficos {
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 12.8%;
  min-width: 12.8%;
  max-width: 12.8%;
  display: flex;
}

.vectors-wrapper-126 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 84px;
  display: flex;
}

.imagen-2 {
  object-fit: cover;
  border-radius: 10px;
}

.tarjeta-curso-contenido {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 64.5%;
  min-width: 64.5%;
  max-width: 64.5%;
  height: 92px;
  display: flex;
}

.text-106 {
  text-align: left;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 119%;
}

.frame-140 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.tarjeta-curso-contenido-info {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 520px;
  display: flex;
}

.tarjeta-curso-info1 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.profesor-2 {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.duracion {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-127 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.text-107-rojo {
  color: #ff3a3a;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.tarjeta-curso-info2 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 47px;
  display: flex;
}

.tarjeta-curso-horas-lecciones-idioma {
  flex-flow: column;
  flex: 0 auto;
  justify-content: space-around;
  align-items: flex-start;
  width: auto;
  min-width: auto;
  height: 47px;
  display: flex;
}

.tiempo {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.horas {
  text-align: left;
  white-space: nowrap;
  overflow-wrap: normal;
  width: auto;
  height: 22px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
}

.lecciones-2 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-128 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 19px;
  display: flex;
}

.idioma-2 {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-108 {
  text-align: right;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
}

.text-109 {
  white-space: nowrap;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
}

.barra-progreso-2 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 17.5%;
  min-width: 17.5%;
  max-width: 17.5%;
  display: flex;
}

.vectors-wrapper-129 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10px;
  display: flex;
}

.text-110 {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.estadisiticas {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.estadisticas-contenido {
  grid-column-gap: 90px;
  grid-row-gap: 90px;
  background-image: linear-gradient(#323644 0%, #32364400 54%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 38px 46px;
  display: flex;
}

.dato-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-130 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  display: flex;
}

.lecciones-completadas-2 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
}

.vectors-wrapper-131 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 41.2075px;
  height: 42px;
  display: flex;
}

.vectors-wrapper-132 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 43px;
  display: flex;
}

.nuevos-cursos {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #1d1d1d;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  height: 100%;
  display: flex;
}

.barra-de-busqueda---vista-alumno-3 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background-color: #d9d9d9;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 441px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
}

.vectors-wrapper-134 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.text-111 {
  color: #707585;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 112.5%;
}

.frame-botones {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 441px;
  display: flex;
}

.boton-3 {
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.texto-4 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.frame-cursos {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  grid-auto-flow: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.curso-explorar {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;
  cursor: pointer;
  background-color: #323644;
  border-radius: 10px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 140px;
  display: flex;
}

.curso-explorar:hover {
  color: #707585;
  background-color: #32364461;
}

.frame-imagen {
  opacity: .55;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 125px;
  max-width: 125px;
  height: 140px;
}

.info-3 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 140px;
  padding: 25px 20px;
  display: flex;
}

.frame-141 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.calificaciongraduados {
  grid-column-gap: 11px;
  grid-row-gap: 11px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-right: 1px;
  display: flex;
}

.calificacion-5 {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-135 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.text-112 {
  white-space: nowrap;
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
}

.graduados {
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
}

.frame-hora-y-lecciones {
  grid-column-gap: 13px;
  grid-row-gap: 13px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.tiempo-2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 20px;
  display: flex;
}

.vectors-wrapper-136 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.lecciones-3 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-56 {
  border-left: 1px dashed #fafafa;
  border-right: 1px dashed #fafafa;
  width: 1px;
  height: 600px;
}

.btn-video-recurso-seleccionado {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #1d1d1d;
  cursor: pointer;
  background-image: linear-gradient(#1de8f5, #1de8f5);
  border: 1px solid #1de8f5;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
  font-family: Poppins, sans-serif;
  display: flex;
}

.btn-infografia-recursos-seleccionado {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #1d1d1d;
  cursor: pointer;
  background-image: linear-gradient(#ff8d8d, #ff8d8d);
  border: 1px solid #ff8d8d;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 9px 13px;
  display: flex;
}

.btn-precentacion-recursos-seleccionado {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #1d1d1d;
  cursor: pointer;
  background-image: linear-gradient(#a5ff32, #a5ff32);
  border: 1px solid #a5ff32;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 9px 15px;
  display: flex;
}

.btn-plantilla-recursos-seleccionado {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #1d1d1d;
  cursor: pointer;
  background-image: linear-gradient(#5ae7c5, #5ae7c5);
  border: 1px solid #5ae7c5;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px;
  display: flex;
}

.vectors-wrapper-144 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.vectors-wrapper-145 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.vectors-wrapper-146, .vectors-wrapper-148, .vectors-wrapper-150 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  display: flex;
}

.curso-comprado-a {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  position: relative;
}

.respuesta-conjunto {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-152 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.vectors-wrapper-153 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 53.5462px;
  height: 53.5462px;
  display: flex;
}

.v-line-load {
  z-index: 1;
  background-image: linear-gradient(#33333373, #33333373);
  border-radius: 10px;
  width: 50%;
  height: 7px;
  position: absolute;
}

.v-line-progress {
  z-index: 3;
  background-image: linear-gradient(#942f2f, #942f2f);
  border-radius: 10px;
  width: 20%;
  height: 7px;
  position: absolute;
}

.v-line-hover {
  z-index: 2;
  background-image: linear-gradient(#ffffff9e, #ffffff9e);
  border-radius: 10px;
  width: 40%;
  height: 7px;
  position: absolute;
}

.contenido-curso-perfil-fill {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-image: linear-gradient(#1b1b1b73, #1b1b1b73);
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.text-90-sin-info {
  color: #fff;
  text-align: center;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 119%;
}

.contenedor-cursos-perfil {
  flex-direction: column;
  align-items: center;
  width: 315px;
  height: 100%;
  padding-right: 10px;
  display: flex;
  position: relative;
  overflow: auto;
}

.contenido-temariocalificacion {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #323644;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  height: 700px;
  padding: 16px 22px;
  display: flex;
  position: relative;
}

.frame-146 {
  grid-column-gap: 34px;
  grid-row-gap: 34px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.frame-147 {
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.btn-filtro-seleccionado {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-color: #323644;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 125px;
  padding: 5px;
  display: flex;
}

.vectors-wrapper-154 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.text-118 {
  color: #fff;
  text-align: center;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 112.5%;
}

.btn-filtro {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  cursor: pointer;
  background-color: #32364461;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 125px;
  padding: 5px;
  display: flex;
}

.text-119 {
  color: #707585;
  text-align: center;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 112.5%;
}

.vectors-wrapper-155 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.tcm-viigencia-proxima-a-vencer {
  color: #eef242;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.text-106-not-purchased {
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 119%;
}

.contenedo-card-prox-vencer {
  width: 100%;
  height: 500px;
  padding-right: 10px;
  overflow: auto;
}

.progreso-curso-curso-seleccionado {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  background-color: #323644;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 294px;
  height: 122px;
  padding: 14px 9px;
  display: flex;
}

.container-cnc-home-alumno {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 465px;
  display: flex;
  overflow: auto;
}

.div-block-58 {
  background-image: linear-gradient(to right, #1d1d1d, #fff0 1%), linear-gradient(#fff0, #1d1d1d00 76%, #1d1d1d), url('../images/profesorPerfil.png');
  background-position: 0 0, 0 0, 50% 30%;
  background-size: auto, auto, 100%;
  width: 100%;
  height: 245px;
}

.div-block-59 {
  width: 100%;
}

.frame-31 {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-left: 10px;
  display: flex;
  position: relative;
}

.div-block-60 {
  color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  display: flex;
  position: relative;
}

.img-pofile {
  background-image: linear-gradient(#941d1d, #941d1d);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  display: flex;
  position: absolute;
  top: -28px;
}

.text-block-23 {
  color: #fff;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;
  font-size: 26px;
  font-weight: 600;
  display: flex;
}

.contenido-5 {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding: 8px 10px;
  display: flex;
  position: relative;
  top: 60px;
  overflow: auto;
}

.frame-149 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  display: flex;
  position: relative;
}

.nombre-profesor {
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.vectors-wrapper-156 {
  cursor: pointer;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
  position: absolute;
  inset: 0% 0% auto auto;
}

.profesion-profesor {
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.content-info-profesor {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  display: flex;
}

.item-info {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  display: flex;
}

.text-item-info {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 150.6%;
}

.text-item-info-2 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 150.6%;
}

.vectors-wrapper-157 {
  background-image: linear-gradient(#fdfdfd80, #fdfdfd80);
  justify-content: center;
  align-items: center;
  width: 2px;
  height: 100%;
  display: flex;
}

.content-descripcion-profesor {
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 65%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  position: relative;
}

.text-descripcion-profesor {
  color: #fff;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.calificacion-profesor {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.conteniido-mis-cursos-profesor {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 930px;
  display: flex;
  position: static;
}

.cursos-profesor {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  width: 70%;
  height: 100%;
  display: flex;
}

.info-cruso-profesor {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  width: 530px;
  height: 100%;
  display: flex;
}

.card-info-profesor {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  color: #848282;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 80%;
  height: 100%;
  display: flex;
}

.tcm-seleccionado-profesor {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  cursor: pointer;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  max-width: 490px;
  height: 150px;
  padding: 20px 27px;
  display: flex;
}

.tcm-normal-profesor {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  cursor: pointer;
  background-color: #32364461;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  max-width: 490px;
  height: 150px;
  padding: 20px 27px;
  display: flex;
}

.div-block-63 {
  width: 90px;
  height: 90px;
}

.tcm-sub-datos-text-profesor {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.tmc-sub-datos-profesor {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.vectors-wrapper-161 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.horas-2 {
  color: #a5ff32;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.vectors-wrapper-162 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 19px;
  display: flex;
}

.num-de-lecciones {
  color: #1de8f5;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.text-120 {
  color: #fbff42;
  text-align: left;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
}

.circulo-num-mis-cursos-profesor {
  background-image: linear-gradient(#707585, #707585);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 30px;
  padding: 5px 10px;
  display: flex;
}

.frame-151 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 1px solid #848282;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 32px;
  padding: 3px 15px;
  display: flex;
}

.frame-151:hover {
  background-image: linear-gradient(#84828233, #84828233);
}

.frame-152 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 1px solid #a5ff32;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 32px;
  padding: 3px 15px;
  display: flex;
}

.frame-152:hover {
  background-image: linear-gradient(#a5ff3233, #a5ff3233);
}

.contenido-btns-a-profesor {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.frame-153 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 1px solid #1de8f5;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 3px 15px;
  display: flex;
}

.frame-153:hover {
  background-image: linear-gradient(#1de8f533, #1de8f533);
}

.frame-156 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.frame-157 {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-121 {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 110%;
}

.text-122 {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 110%;
}

.vectors-wrapper-173 {
  color: #b1bdcd;
  -webkit-text-stroke-color: #b1bdcd;
  border: 1px dashed #b1bdcd;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 100%;
  display: flex;
}

.vectors-wrapper-174 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
}

.text-123 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 110%;
}

.vectors-wrapper-175 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
}

.text-124 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 110%;
}

.vectors-wrapper-176 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
}

.text-125 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 110%;
}

.tcm-sub-datos-contenedor-profesor {
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  display: flex;
}

.card-info-seleccionado-profesor {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 80%;
  height: 100%;
  display: flex;
}

.text-107-amarillo {
  color: #eef242;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.text-107-azul {
  color: #1de8f5;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.vectors-wrapper-177 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  display: flex;
}

.div-block-39-a {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5px;
  display: flex;
}

.text-title-curso {
  color: #fff;
  width: 100%;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.conteniido-curso-profesor {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  height: 930px;
  display: flex;
  position: static;
}

.btn-curso-profesor {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 170px;
  height: 45px;
  padding: 9px 30px;
  display: flex;
}

.btn-curso-profesor:hover {
  background-image: linear-gradient(#ffffff3d, #ffffff3d);
}

.vectors-wrapper-178 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  display: flex;
}

.text-126 {
  text-align: center;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 112.5%;
}

.div-block-64 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.frame-163 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.frame-164 {
  grid-column-gap: 41px;
  grid-row-gap: 41px;
  background-image: linear-gradient(#fff, #fff);
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.vectors-wrapper-180 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 16.5px;
  height: 15.5833px;
  display: flex;
}

.vectors-wrapper-181 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 14.6667px;
  height: 18.3333px;
  display: flex;
}

.vectors-wrapper-182 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18.3333px;
  height: 14.6667px;
  display: flex;
}

.vectors-wrapper-183 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18.3333px;
  height: 16.5px;
  display: flex;
}

.vectors-wrapper-184 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 16.5px;
  height: 16.9214px;
  display: flex;
}

.contenedor-buscador {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
  width: 50%;
  display: flex;
}

.container-modal-body {
  grid-column-gap: 63px;
  grid-row-gap: 63px;
  background-color: #323644;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-right: 5px;
  display: flex;
  position: relative;
  overflow: auto;
}

.frame-modulos-y-lecciones {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.text-127 {
  color: #707585;
  background-image: linear-gradient(#323644, #323644);
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 119%;
  position: sticky;
  top: 0;
}

.seccion {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-128 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 119%;
}

.frame-165 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.text-129 {
  color: #a5ff32;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.text-130 {
  color: #fff;
  text-align: right;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 119%;
}

.lateral-2 {
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  display: flex;
  position: sticky;
  top: 0;
}

.modulos-2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-color: #494f63;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.vectors-wrapper-185 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.frame-166 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-131 {
  color: #fff;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 119%;
}

.container-modal-alumno {
  grid-column-gap: 63px;
  grid-row-gap: 63px;
  background-color: #323644;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  width: 800px;
  height: 494px;
  padding: 35px 60px;
  display: flex;
  position: relative;
}

.btn-add-modulo {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  cursor: pointer;
  background-color: #1d90f5;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 31px;
  padding: 8px 7px;
  display: flex;
}

.btn-add-modulo:active {
  background-color: #6db4f3;
}

.vectors-wrapper-186 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.text-132 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
}

.container-curso-profesor {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  display: flex;
  position: relative;
}

.container-info-general-body {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #323644;
  flex-direction: row;
  flex: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-right: 5px;
  display: flex;
  position: relative;
  overflow: auto;
}

.div-contenido {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.text-133 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
}

.section-info-general-aprenderas {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #323644cc;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.separador-info-general {
  border: 1px dashed #b1bdcd;
  width: 1px;
  height: 100%;
}

.div-info-general-puntos-aprendizaje {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template: "Area"
                 "."
                 "."
                 / auto auto;
  grid-auto-columns: minmax(auto, auto);
  justify-content: start;
  place-items: start center;
  width: 100%;
  display: grid;
}

.title-info-general-section {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 119%;
}

.container-curso-profesor-body {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  background-color: #323644;
  flex-direction: row;
  flex: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-right: 5px;
  display: flex;
  position: relative;
  overflow: auto;
}

.div-seccion-curso-temario {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.text-block-26 {
  color: #64d7fd;
  font-weight: 600;
}

.div-block-66 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  font-size: 30px;
  display: flex;
}

.text-block-26-1 {
  color: #64d7fd00;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #64d7fd;
  white-space: normal;
  word-break: normal;
  overflow-wrap: normal;
  font-weight: 600;
}

.text-block-27 {
  color: #64d7fd;
  font-weight: 600;
}

.text-block-28 {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.text-block-29 {
  color: #fff;
  text-align: justify;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}

.div-block-67 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  display: flex;
}

.vectors-wrapper-187 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.item-recurso-contenedor {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.item-recurso-text-video {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #1de8f5;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.item-recurso-text-precentacion {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #a5ff32;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.item-recurso-text-plantilla {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #5ae7c5;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.item-recurso-text-infografia {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #ff8d8d;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.text-block-30 {
  color: #fff;
  font-size: 13px;
}

.separador-recursos-profesor {
  border: 1px dashed #b1bdcd;
  width: 100%;
  height: 1px;
}

.div-contenido-for-1 {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  display: flex;
  position: relative;
}

.div-contenido-foro-2 {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 60%;
  display: flex;
  position: relative;
}

.item-pregunta {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-134 {
  color: #fff;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 118%;
}

.text-135 {
  color: #1de8f5;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.frame-117 {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.text-136 {
  color: #b1bdcd;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.vectors-wrapper-188 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 3px;
  height: 3px;
  display: flex;
}

.vectors-wrapper-189 {
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 0;
  display: flex;
}

.item-pregunta-2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.contenit-item-pregunta-foro {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.more-info-foro-der {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.div-block-68 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-end;
  width: 100%;
  height: 250px;
  display: flex;
}

.div-block-69 {
  background-image: linear-gradient(#707585, #707585);
  width: 100%;
  height: 1px;
}

.div-block-70 {
  width: 100%;
}

.div-block-71 {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  height: 100%;
  display: flex;
}

.div-block-72 {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.editor-class {
  color: #fff;
  border: 1px solid #707585;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  font-size: 12px;
  display: flex;
}

.menu-perfil---confirmado {
  grid-row-gap: 17px;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 15px 25px 30px;
  display: flex;
}

.vectors-wrapper-190 {
  cursor: pointer;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
}

.text-138 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 119%;
  text-decoration: none;
}

.frame-167 {
  grid-row-gap: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-139 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 119%;
  text-decoration: none;
}

.text-140 {
  color: #707585;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.frame-244 {
  grid-column-gap: 6px;
  border-radius: 8.6413px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 3px;
  display: flex;
}

.vectors-wrapper-192 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 12px;
  display: flex;
}

.vectors-wrapper-193 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 7px;
  height: 7px;
  display: flex;
}

.frame-248 {
  grid-column-gap: 8.75px;
  cursor: pointer;
  border: .875px solid #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 21px;
  padding: 6.125px 14.875px;
  display: flex;
}

.frame-248:hover {
  background-image: linear-gradient(#4b4b4b80, #4b4b4b80);
}

.text-141 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.div-block-73 {
  background-image: linear-gradient(#707585, #707585);
  width: 100%;
  height: 1px;
}

.perfil {
  grid-row-gap: 20px;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 30px;
  display: flex;
}

.text-142 {
  color: #fff;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.frame-258 {
  grid-row-gap: 31px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.vectors-wrapper-195 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 73px;
  display: flex;
}

.frame-255 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: 48% 48%;
  grid-auto-columns: 50%;
  place-content: end space-between;
  place-items: end;
  width: 100%;
  display: grid;
}

.frame-251 {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.text-143 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.frame-241 {
  grid-column-gap: 10px;
  background-color: #707585;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 9px 13px;
  display: flex;
}

.input-info-personal {
  color: #fff;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.frame-246 {
  grid-column-gap: 10px;
  background-color: #464954;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 9px 13px;
  display: flex;
}

.text-145 {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.frame-245 {
  grid-column-gap: 10px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 7px 17px;
  display: flex;
}

.frame-245:hover {
  background-image: linear-gradient(#e7e6e680, #e7e6e680);
}

.frame-259 {
  grid-column-gap: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.frame-249 {
  grid-column-gap: 10px;
  color: #323644;
  background-color: #fff;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 7px 17px;
  display: flex;
}

.frame-249:hover {
  color: #fff;
  background-image: linear-gradient(#818181, #818181);
}

.text-146 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.frame-31-copy {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  padding-left: 10px;
  display: flex;
  position: relative;
}

.btn-change-pass {
  grid-column-gap: 10px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  display: flex;
}

.btn-change-pass:hover {
  background-image: linear-gradient(#e7e6e680, #e7e6e680);
}

.text-btn-change-pass {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.certificado-activo {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  border: 1px dashed #fff;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  padding: 16px 20px;
  display: flex;
}

.btn-cerificado-activo {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px 26px;
  display: flex;
}

.btn-cerificado-activo:hover {
  background-image: linear-gradient(#ffffff2e, #ffffff2e);
}

.vectors-wrapper-197 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 79px;
  height: 79px;
  text-decoration: none;
  display: flex;
}

.btn-cerificado-inactivo {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #575b75;
  border: 1px solid #575b75;
  border-radius: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px 26px;
  display: flex;
}

.vectors-wrapper-218 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  text-decoration: none;
  display: flex;
}

.tcm-certificado {
  color: #1de8f5;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.restante {
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  color: #1de8f5;
  text-align: left;
  border: .5px solid #1de8f5;
  border-radius: 5px;
  flex: 0 auto;
  justify-content: center;
  align-self: auto;
  align-items: center;
  margin-right: 0;
  padding: 7px 10px;
  line-height: 20%;
  text-decoration: none;
  display: flex;
}

.restante:hover {
  background-color: #1de8f524;
}

.vectors-wrapper-215 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 17px;
  text-decoration: none;
  display: flex;
}

.text-171 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 90%;
  text-decoration: none;
}

.text-curso-info {
  color: #fff;
  text-align: justify;
  overflow-wrap: break-word;
  width: 100%;
  height: 100%;
  min-height: 0;
  margin-bottom: 7px;
  padding-bottom: 0;
  padding-right: 10px;
  font-size: 12px;
  overflow: auto;
}

.vectors-wrapper-14 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  display: flex;
}

.curso-info-more {
  grid-column-gap: 13px;
  grid-row-gap: 10px;
  background-color: #323644;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  height: 100%;
  padding: 15px 30px;
  display: flex;
}

.texts-curso-info {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.btn-card-curso {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  display: flex;
}

.titulo-curso-info {
  color: #fff;
  text-align: center;
  object-fit: fill;
  width: 100%;
  height: 50px;
  font-weight: 600;
  overflow: visible;
}

.precio-heart {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-radius: 5px;
  flex: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  display: flex;
}

.btn-card-curso-text {
  font-size: 14px;
  font-weight: 400;
}

.curso-nuevo-titulo {
  color: #fff;
  text-align: left;
  overflow-wrap: normal;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-12 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.hora-lecciones-1 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.calificacion-graduados-1 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  object-fit: fill;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  display: flex;
}

.curso-info {
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: auto;
  place-content: stretch space-between;
  place-items: stretch stretch;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: grid;
}

.texto-calificacion-graduados {
  color: #b1bdcd;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
}

.curso-normal {
  grid-column-gap: 13px;
  grid-row-gap: 10px;
  background-color: #323644;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 280px;
  max-width: 280px;
  height: 330px;
  padding-bottom: 0;
  display: flex;
}

.texto-hora-lecciones {
  color: #b1bdcd;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  display: flex;
}

.profesor {
  color: #ffffffa1;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
}

.vectors-wrapper-11 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  border-radius: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  display: flex;
  box-shadow: 7px 7px 10px 1px #0003;
}

.calificacion-graduados-2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  display: flex;
}

.vectors-wrapper-13 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.precio {
  color: #fff;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  display: flex;
}

.curso-nuevo-contenido {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  position: static;
  top: -15px;
}

.vectors-wrapper-219 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  display: flex;
}

.hora-lecciones-2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tarjeta-curso-1-header {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1-titulo {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  aspect-ratio: auto;
  color: #fff;
  white-space: normal;
  word-break: normal;
  object-fit: fill;
  flex: 0 auto;
  width: 100%;
  height: 28px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.tarjeta-curso-1-titulo.truncated-text {
  text-align: center;
}

.tarjeta-curso-1-horas {
  grid-column-gap: 6px;
  justify-content: flex-start;
  align-items: center;
  width: 89px;
  height: 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1-profesor {
  color: #ffffffa1;
  object-fit: fill;
  width: 100%;
  height: 14px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 96%;
  text-decoration: none;
}

.vectors-wrapper-222 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 16.6667px;
  height: 16.6667px;
  text-decoration: none;
  display: flex;
}

.text-176 {
  color: #b1bdcd;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.vectors-wrapper-220 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 19px;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1 {
  border: 0 solid #000;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  height: 355px;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.tarjeta-curso-1-calificacion {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1-lecciones {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-223 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 16px;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1-info-1 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1-contenido {
  background-color: #323644;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 225px;
  padding: 25px 20px;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1-precio {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 29px;
  display: flex;
}

.graduados-2 {
  color: #b1bdcd;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.vectors-wrapper-221 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 18px;
  text-decoration: none;
  display: flex;
}

.horas-4 {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.tarjeta-curso-1-imagen {
  object-fit: cover;
  background-color: #d9d9d9;
}

.tarjeta-curso-1-contenido-2 {
  background-color: #323644;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 355px;
  padding: 25px 20px;
  text-decoration: none;
  display: flex;
}

.tarjeta-curso-1-descripcion {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 240px;
  height: 207px;
  text-decoration: none;
  display: flex;
}

.text-177 {
  color: #fff;
  text-align: justify;
  object-fit: fill;
  flex-flow: column;
  width: 240px;
  height: 207px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.texto-5 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 101%;
  text-decoration: none;
}

.botones-4 {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  height: 29px;
  padding: 10px 20px;
  text-decoration: none;
  display: flex;
}

.botones-4:hover {
  background-color: #bbb9b957;
}

.tarjeta-1-curso-estatus {
  grid-column-gap: 10px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.tarjeta-1-curso-texto-disponible {
  color: #a5ff32;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-1-curso-texto-disponible-prox {
  color: #1d90f5;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-3-curso-header-2 {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-texto-titulo-4 {
  width: 100%;
  height: 42px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 133%;
  text-decoration: none;
  overflow: hidden;
}

.tarjeta-3-curso-texto-estatus-2 {
  color: #a5ff32;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.tarjeta-3-curso-texto-estatus-2.truncated-text1 {
  width: 100%;
}

.tarjeta-3-curso-texto-estatus-3 {
  color: #1d90f5;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.tarjeta-3-curso-texto-estatus-3.truncated-text1 {
  width: 100%;
}

.tarjeta-1-curso-disponible {
  grid-column-gap: 10px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.tarjeta-1-curso-texto-disponible-2 {
  color: #a5ff32;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-1-curso-texto-disponible-3 {
  color: #1d90f5;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-titulos {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 38px;
  display: flex;
}

.tarjeta-5-curso-texto-titulo {
  color: #fff;
  white-space: normal;
  box-orient: vertical;
  max-lines: 2;
  text-overflow: ellipsis;
  height: 18px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
  display: -webkit-box;
  overflow: hidden;
}

.tarjeta-5-curso-texto-titulo.truncated-text1 {
  width: 100%;
}

.tarjeta-5-curso-texto-nivel {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.div-block-74 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.div-3 {
  background-color: #837272;
  width: 17.5%;
  height: 50px;
}

.div-block-76 {
  background-color: #000;
  width: 100%;
}

.div-1 {
  background-color: #df0000;
  width: 12.8%;
  height: 50px;
}

.div-2 {
  background-color: #000;
  width: 64.5%;
  height: 50px;
}

.sect-footer-datos {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-contacto-md-footer {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.section-text-footer1 {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}

.vectors-wrapper-46, .vectors-wrapper-44 {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 80%;
  display: flex;
}

.div-block-77 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.div-block-78 {
  display: block;
}

.tarjeta-4-curso-seleccionado {
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-header {
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 62px;
  display: flex;
}

.tarjeta-4-curso-titulo {
  color: #fff;
  flex-flow: column;
  place-content: center;
  align-items: flex-start;
  width: 100%;
  max-height: 36px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
  overflow: hidden;
}

.tarjeta-4-curso-nivel {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-4-curso-contenido {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 66px;
  display: flex;
}

.tarjeta-4-curso-info-1 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 31px;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-vigencia {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  height: 31px;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-texto-vigencia {
  color: #a5ff32;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-4-curso-horas {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-texto-horas {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-4-curso-info-2 {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 22px;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-tiempo {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-texto-tiempo {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-4-curso-lecciones {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-texto-lecciones {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-4-curso-idioma {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-texto-idioma {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-4-curso-progreso {
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.rectangle-9 {
  background-color: #21242e;
  border-radius: 30px;
}

.rectangle-10 {
  background-color: #b1bdcd;
  border-radius: 30px;
}

.tarjeta-4-curso-texto-porcentaje {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.tarjeta-4-curso-certificado {
  grid-column-gap: 6px;
  border: .5px solid #1de8f5;
  border-radius: 5px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 140px;
  min-width: 140px;
  max-width: 140px;
  height: 31px;
  min-height: 31px;
  max-height: 31px;
  padding: 7px 5px;
  text-decoration: none;
  transition: box-shadow .125s, background-color .2s;
  display: flex;
}

.tarjeta-4-curso-certificado:hover {
  background-color: #1de8f552;
  box-shadow: 0 0 20px -6px #1de8f5;
}

.tarjeta-4-curso-texto-certificado {
  color: #1de8f5;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 119%;
  text-decoration: none;
}

.tarjeta-4-curso-no-seleccionado {
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  display: flex;
}

.property-1-default-seleccionado-text-vigencia-completado {
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-texto-sin-acceso {
  color: #9747ff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.property-1-default-no-seleccionado-text-vigencia-completado {
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-2-curso {
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 329px;
  max-width: 409px;
  height: 491px;
  text-decoration: none;
  display: flex;
}

.tarjeta-2-curso-contenido {
  grid-row-gap: 20px;
  background-color: #323644;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 291px;
  min-height: 291px;
  max-height: 291px;
  padding: 15px;
  text-decoration: none;
  display: flex;
}

.tarjeta-2-curso-calificacion {
  grid-column-gap: 17px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  text-decoration: none;
  display: flex;
}

.tarjeta-2-curso-estrellas {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-2-curso-texto-calificacion {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.tarjeta-2-curso-titulos {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-2-curso-titulo-leccion {
  color: #fff;
  place-content: center flex-start;
  align-items: center;
  width: 100%;
  max-height: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
  overflow: hidden;
}

.tarjeta-2-curso-titulo-curso {
  color: #707585;
  width: 100%;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.tarjeta-2-curso-info {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-2-curso-descripcion {
  color: #fff;
  place-content: center flex-start;
  align-items: center;
  width: 100%;
  max-height: 57px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 133%;
  text-decoration: none;
  overflow: hidden;
}

.tarjeta-2-curso-tiempo {
  color: #707585;
  height: 18.6125px;
  min-height: 18.6125px;
  max-height: 18.6125px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 133%;
  text-decoration: none;
}

.tarjeta-2-curso-boton {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  text-decoration: none;
  display: flex;
}

.boton-1 {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.boton-1:hover {
  background-color: #9b9b9b;
}

.boton-1-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 101%;
  text-decoration: none;
}

.star {
  background-color: #fbff42;
}

.property-1-boton-1 {
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  padding: 10px 20px;
  text-decoration: none;
  display: flex;
}

.img-2 {
  width: 100%;
}

.disponible-ahora {
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 298px;
  height: 426px;
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-contenido {
  grid-row-gap: 22px;
  background-color: #323644;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 286px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-info {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 123px;
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-horas {
  grid-column-gap: 7px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-texto-horas {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-6-curso-recursos {
  grid-column-gap: 11px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-texto-recursos {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-6-curso-dias {
  grid-column-gap: 9px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-texto-dias {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-6-curso-certificado {
  grid-column-gap: 11px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-texto-certificado {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.botones-tarjeta-6-curso {
  grid-column-gap: 20px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.boton-tarjeta-6-curso-info {
  color: #323644;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 35px;
  padding: 10px 20px;
  text-decoration: none;
  transition: color .2s, border-color .2s, background-color .2s;
  display: flex;
}

.boton-tarjeta-6-curso-info:hover {
  color: #fff;
  background-color: #ffffff61;
  border-color: #8a8888;
}

.boton-tarjeta-6-curso-texto {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 101%;
  text-decoration: none;
}

.property-1-boton-1-variante {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  height: 29px;
  padding: 10px 20px;
  text-decoration: none;
  display: flex;
}

.disponible-proximamente {
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 426px;
  text-decoration: none;
  display: flex;
}

.mis-cursos-alumno-body {
  grid-row-gap: 29px;
  background-color: #1d1d1d;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 802px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
  text-decoration: none;
  display: flex;
}

.header-3 {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1297px;
  height: 120px;
  text-decoration: none;
  display: flex;
}

.mis-cursos-alumno-perfil {
  grid-column-gap: 10px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 70px;
  display: flex;
}

.vectors-wrapper-224 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 25px;
  text-decoration: none;
  display: flex;
}

.perfil-icon {
  grid-column-gap: 10px;
  background-color: #9747ff;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  text-decoration: none;
  display: flex;
}

.perfil-icon-texto {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
}

.mis-cursos-alumno-titulo {
  grid-column-gap: 11px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 30px;
  display: flex;
}

.mis-cursos-alumno-texto-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.conteo-icon {
  grid-column-gap: 10px;
  background-color: #323644;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
  display: flex;
}

.conteo-icon-texto {
  color: #b1bdcd;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
}

.mis-cursos-alumno-contenido {
  grid-column-gap: 35px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1297px;
  height: calc(100% - 149px);
  min-height: calc(100% - 149px);
  max-height: calc(100% - 149px);
  text-decoration: none;
  display: flex;
  position: relative;
}

.mis-cursos-alumno-cursos {
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  scrollbar-width: none;
  flex-flow: wrap;
  place-content: flex-start center;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  transition-property: opacity, width, min-height;
  transition-duration: .2s, .125s, .125s;
  transition-timing-function: ease, ease, ease;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.mis-cursos-alumno-info {
  grid-column-gap: 52px;
  grid-row-gap: 52px;
  scrollbar-width: none;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 329px;
  max-width: 409px;
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.apartado-tarjeta-2-lecciones {
  grid-row-gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 409px;
  height: 241px;
  padding: 10px 8px;
  text-decoration: none;
  display: flex;
}

.apartado-tarjeta-2-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 133%;
  text-decoration: none;
}

.apartado-tarjeta-2-modulo {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 133%;
  text-decoration: none;
}

.apartado-tarjeta-2-contenido-leccion-1 {
  grid-column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 21px;
  min-height: 21px;
  max-height: 21px;
  text-decoration: none;
  display: flex;
}

.apartado-tarjeta-2-leccion-1 {
  grid-column-gap: 7px;
  justify-content: flex-start;
  align-items: center;
  height: 21px;
  display: flex;
}

.apartado-tarjeta-2-texto-leccion-1 {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.apartado-tarjeta-2-texto-tiempo {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.apartado-tarjeta-2-contenido-leccion-2 {
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.apartado-tarjeta-2-leccion-2 {
  grid-column-gap: 7px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 21px;
  display: flex;
}

.apartado-tarjeta-2-texto-leccion-2 {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.apartado-tarjeta-2-contenido-leccion-3 {
  grid-column-gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.apartado-tarjeta-2-leccion-3 {
  grid-column-gap: 7px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 21px;
  display: flex;
}

.apartado-tarjeta-2-texto-leccion-3 {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.img-3 {
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 200px;
}

.tarjeta-4-curso-default-seleccionado {
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  transition: transform .5s, opacity .5s;
  display: flex;
}

.tarjeta-4-curso-con-acceso-certificado-no-seleccionado {
  cursor: pointer;
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  transition: background-color .25s;
  display: flex;
}

.tarjeta-4-curso-con-acceso-certificado-no-seleccionado:hover {
  background-color: #323644;
}

.tarjeta-4-curso-barra-progreso-general {
  grid-column-gap: 10px;
  background-color: #21242e;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 5px;
  min-height: 5px;
  max-height: 5px;
  padding-bottom: 0;
  padding-right: 0;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-default-no-seleccionado {
  cursor: pointer;
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  transition: background-color .25s, opacity .5s, transform .5s;
  display: flex;
}

.tarjeta-4-curso-default-no-seleccionado:hover {
  background-color: #323644;
}

.tarjeta-4-curso-sin-acceso-no-seleccionado {
  cursor: pointer;
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  transition: background-color .25s;
  display: flex;
}

.tarjeta-4-curso-sin-acceso-no-seleccionado:hover {
  background-color: #323644;
}

.tarjeta-4-curso-sin-acceso {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-completato-certificado-no-seleccionado {
  cursor: pointer;
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  transition: background-color .25s;
  display: flex;
}

.tarjeta-4-curso-completato-certificado-no-seleccionado:hover {
  background-color: #323644;
}

.tarjeta-4-curso-sin-acceso-seleccionado {
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-4-curso-texto-sin-accesso {
  color: #9747ff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-4-curso-completado-certificado-seleccionado, .tarjeta-8-tarjeta-4-curso-con-acceso-certificado-seleccionado {
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 368px;
  max-width: 409px;
  height: 240px;
  padding: 18px 25px;
  text-decoration: none;
  display: flex;
}

.iconos-calendar-verde {
  border-style: solid;
  border-width: undefinedpx;
  border-color: #a5ff32;
}

.tarjeta-2-boton {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color .2s;
  display: flex;
}

.tarjeta-2-boton:hover {
  background-color: #8a8a8a;
}

.tarjeta-2-texto-boton {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 101%;
  text-decoration: none;
}

.img-4 {
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 200px;
}

.tarjeta-7 {
  grid-column-gap: 13px;
  cursor: pointer;
  background-color: #26282d;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 294px;
  min-width: 294px;
  max-width: 294px;
  height: 122px;
  min-height: 122px;
  max-height: 122px;
  padding: 15px 10px;
  text-decoration: none;
  transition: background-color .25s;
  display: flex;
}

.tarjeta-7:hover {
  background-color: #323644;
}

.porcentaje {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 89px;
  min-width: 89px;
  max-width: 89px;
  height: 89px;
  min-height: 89px;
  max-height: 89px;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.tarjeta-7-contenido {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 89px;
  display: flex;
}

.tarjeta-7-titulo {
  color: #fff;
  width: 100%;
  max-height: 39px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
  overflow: hidden;
}

.tarjeta-7-titulo.truncated-text {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  max-height: 39px;
}

.tarjeta-7-leccion {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-7-tiempo {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 15px;
  display: flex;
}

.tarjeta-7-tiempo-1, .tarjeta-7-tiempo-2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.default {
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 330px;
  max-width: 385px;
  height: 140px;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-contenido {
  grid-row-gap: 10px;
  background-color: #323644;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 140px;
  padding: 25px 20px;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-header {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-texto-titulo {
  color: #fff;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 42px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 133%;
  text-decoration: none;
  overflow: hidden;
}

.tarjeta-3-curso-texto-estatus {
  color: #a5ff32;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.tarjeta-3-curso-info {
  grid-column-gap: 30px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-calificacion {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-texto-calificacion {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 133%;
  text-decoration: none;
}

.tarjeta-3-curso-lecciones {
  grid-column-gap: 6px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-texto-lecciones {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 133%;
  text-decoration: none;
}

.tarjeta-3-curso-normal {
  background-color: #26282d;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 330px;
  max-width: 385px;
  height: 140px;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-normal:hover {
  background-color: #323644;
}

.home-alumno-estadisticas {
  background-image: linear-gradient(#323644, #32364400 51%);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 119px;
  padding: 38px 46px;
  text-decoration: none;
  display: flex;
}

.home-alumno-estadisticas-info-1 {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 43px;
  display: flex;
}

.home-alumno-estadisticas-numero-lecciones {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.home-alumno-estadisticas-lecciones {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.home-alumno-estadisticas-info-2 {
  grid-column-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 43px;
  display: flex;
}

.home-alumno-estadisticas-numero-horas {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.home-alumno-estadisticas-horas {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.home-alumno-estadisticas-info-3 {
  grid-column-gap: 10px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 43px;
  display: flex;
}

.home-alumno-estadisticas-numero-test {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.home-alumno-estadisticas-test {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.home-alumno-body {
  grid-row-gap: 21px;
  background-color: #1d1d1d;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 64px);
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.home-alumno-contenido-principal {
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 807px;
  max-width: 1297px;
  height: calc(100% - 141px);
  min-height: calc(100% - 141px);
  max-height: calc(100% - 141px);
  text-decoration: none;
  display: flex;
  position: relative;
}

.home-alumno-contenido-1 {
  grid-row-gap: 21px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 807px;
  max-width: 1297px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.home-alumno-progreso {
  grid-row-gap: 14px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.home-alumno-cursos-progreso {
  grid-column-gap: 20px;
  scrollbar-gutter: stable;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 150px;
  padding-bottom: 14px;
  text-decoration: none;
  display: flex;
  overflow-x: hidden;
}

.home-alumno-cursos-progreso:hover {
  overflow-x: auto;
}

.home-alumno-titulo-2 {
  grid-column-gap: 29px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 30px;
  display: flex;
}

.home-alumno-texto-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.conteo-icon-2 {
  grid-column-gap: 10px;
  background-color: #323644;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
  display: flex;
}

.conteo-icon-texto-2 {
  color: #b1bdcd;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
}

.home-alumno-cursos {
  grid-column-gap: 14px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 807px;
  height: 695px;
  text-decoration: none;
  display: flex;
}

.home-alumno-tarjetas-cursos {
  grid-row-gap: 21px;
  scrollbar-gutter: stable;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 783px;
  height: 695px;
  padding-right: 14px;
  text-decoration: none;
  transition-property: width;
  transition-duration: .2s;
  transition-timing-function: ease;
  display: flex;
  overflow: hidden;
}

.home-alumno-tarjetas-cursos:hover {
  overflow-y: auto;
}

.vectors-wrapper-225 {
  justify-content: center;
  align-items: center;
  width: 0;
  text-decoration: none;
  display: flex;
}

.home-alumno-contenido-2 {
  grid-row-gap: 21px;
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 0;
  min-width: 0;
  max-width: 409px;
  text-decoration: none;
  transition-property: width, min-width, opacity;
  transition-duration: .5s, .5s, .125s;
  transition-timing-function: ease, ease, ease;
}

.home-alumno-cursos-explora {
  grid-column-gap: 14px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 837px;
  display: flex;
}

.home-alumno-tarjeta-cursos-explora {
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.home-alumno-explora {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.vectors-wrapper-226 {
  justify-content: center;
  align-items: center;
  width: 0;
  text-decoration: none;
  display: flex;
}

.div-4, .div-5 {
  justify-content: center;
  align-items: center;
  width: 34px;
  min-width: 34px;
  max-width: 34px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  display: flex;
}

.div-6 {
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
}

.header-chido {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1297px;
  height: 120px;
  text-decoration: none;
  display: flex;
}

.home-alumno-linea-divisora {
  background-color: #b1bdcd73;
  border: 1px dashed #000;
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  display: none;
}

.property-1-tarjeta-5-curso-azul {
  grid-column-gap: 25px;
  background-color: #323644;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 783px;
  max-width: 836px;
  height: 130px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-imagen-linea-azul {
  grid-column-gap: 11px;
  grid-row-gap: 11px;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 108px;
  max-width: 126px;
  height: 92px;
  min-height: 92px;
  max-height: 92px;
  display: flex;
}

.tarjeta-5-curso-linea-azul {
  background-color: #1de8f5;
  border-radius: 10px;
  width: 8px;
  min-width: 8px;
  max-width: 8px;
  height: 84px;
  min-height: 84px;
  max-height: 84px;
}

.tarjeta-5-curso-imagen {
  border-radius: 10px;
  width: 100%;
  min-width: 89px;
  max-width: 107px;
  height: 84px;
  min-height: 84px;
  max-height: 84px;
}

.tarjeta-5-curso-contenido {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 458px;
  height: 92px;
  display: flex;
}

.tarjeta-5-curso-info {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 47px;
  display: flex;
}

.tarjeta-5-curso-info-1 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 47px;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-horas {
  grid-column-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-texto-horas {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-idioma {
  grid-column-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-texto-idioma {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-info-2 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 47px;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-lecciones {
  grid-column-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-texto-lecciones {
  color: #b1bdcd;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-horas-restantes {
  grid-column-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 154px;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-texto-horas-restantes {
  color: #b1bdcd;
  align-content: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-vigencia {
  grid-column-gap: 7px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-vigencia-azul {
  color: #1de8f5;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-progreso {
  flex-direction: column;
  flex-grow: 1;
  place-content: center;
  align-items: center;
  width: 100%;
  min-width: 100px;
  max-width: 144px;
  height: 66px;
  display: flex;
}

.tarjeta-5-curso-barra-ejemplo {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 142px;
  max-width: 142px;
  height: 33px;
  min-height: 5px;
  max-height: 5px;
  display: flex;
}

.tarjeta-5-curso-barra-progreso {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 33px;
  display: flex;
}

.tarjeta-5-curso-texto-progreso {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-azul {
  grid-column-gap: 25px;
  cursor: pointer;
  background-color: #26282d;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 783px;
  height: 130px;
  min-height: 130px;
  max-height: 130px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  transition: background-color .25s;
  display: flex;
}

.tarjeta-5-curso-azul:hover {
  background-color: #323644;
}

.barra-buscar {
  grid-column-gap: 15px;
  background-color: #d9d9d9;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 31px;
  padding-left: 9px;
  padding-right: 9px;
  text-decoration: none;
  display: flex;
}

.barra-buscar-texto {
  grid-column-gap: 10px;
  color: #707585;
  cursor: text;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 22px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 112.5%;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-amarilla {
  grid-column-gap: 25px;
  cursor: pointer;
  background-color: #323644;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 783px;
  height: 130px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-amarilla:hover {
  background-color: #26282d;
}

.tarjeta-5-curso-imagen-linea-amarilla {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 115px;
  max-width: 126px;
  height: 1px;
  display: flex;
}

.tarjeta-5-curso-linea-amarilla {
  background-color: #fbff42;
  border-radius: 10px;
  width: 8px;
  min-width: 8px;
  max-width: 8px;
  height: 84px;
  min-height: 84px;
  max-height: 84px;
}

.tarjeta-5-curso-vigencia-amarillo {
  color: #fbff42;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-5-curso-rojo {
  grid-column-gap: 25px;
  cursor: pointer;
  background-color: #323644;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 783px;
  height: 130px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  display: flex;
}

.tarjeta-5-curso-rojo:hover {
  background-color: #26282d;
}

.tarjeta-5-curso-imagen-linea-roja {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 115px;
  max-width: 126px;
  height: 1px;
  display: flex;
}

.tarjeta-5-curso-linea-roja {
  background-color: #ff3a3a;
  border-radius: 10px;
  width: 8px;
  min-width: 8px;
  max-width: 8px;
  height: 84px;
  min-height: 84px;
  max-height: 84px;
}

.tarjeta-5-curso-vigencia-rojo {
  color: #ff3a3a;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.tarjeta-3-curso-proximamente {
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 330px;
  max-width: 385px;
  height: 140px;
  text-decoration: none;
  display: flex;
}

.tarjeta-3-curso-texto-estatus-azul {
  color: #1d90f5;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 133%;
  text-decoration: none;
}

.perfil-alumno-body {
  grid-row-gap: 21px;
  background-color: #1d1d1d;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 1110px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-contenido-principal {
  grid-column-gap: 35px;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 1040px;
  max-width: 1297px;
  height: calc(100% - 141px);
  min-height: calc(100% - 141px);
  max-height: calc(100% - 141px);
  text-decoration: none;
  display: flex;
}

.perfil-alumno-contenido-1 {
  grid-row-gap: 21px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 775px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-busqueda-filtros {
  grid-column-gap: 16px;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-cursos-modulos {
  grid-column-gap: 21px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 52px);
  text-decoration: none;
  display: flex;
}

.perfil-alumno-cursos {
  grid-row-gap: 15px;
  scrollbar-width: none;
  scrollbar-gutter: stable;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 254px;
  max-width: 312px;
  height: 100%;
  padding-right: 14px;
  text-decoration: none;
  display: flex;
  overflow: hidden auto;
}

.perfil-alumno-info-modulos {
  grid-column-gap: 35px;
  background-color: #323644;
  border-radius: 20px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: 501px;
  height: 100%;
  padding: 16px 22px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-modulos {
  grid-row-gap: 30px;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 276px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-right: 14px;
  text-decoration: none;
  display: flex;
  overflow: hidden auto;
}

.perfil-alumno-info {
  grid-row-gap: 18px;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 160px;
  min-width: 160px;
  height: 100%;
  text-decoration: none;
  display: flex;
  overflow-y: hidden;
}

.perfil-alumno-info:hover {
  overflow-y: auto;
}

.perfil-alumno-contenido-2 {
  grid-row-gap: 45px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 230px;
  max-width: 298px;
  height: 743px;
  display: none;
  overflow-y: auto;
}

.perfil-alumno-progreso {
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-texto-progreso {
  color: #fff;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.tarjeta-8-seleccionado {
  cursor: auto;
  background-color: #323644;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 240px;
  max-width: 298px;
  height: 146px;
  padding: 14px 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-8-contenido {
  grid-column-gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-8-titulo {
  color: #fff;
  align-content: center;
  max-height: 48px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
  overflow: hidden;
}

.tarjeta-8-texto-numero {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.tarjeta-8 {
  cursor: pointer;
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 240px;
  max-width: 298px;
  height: 146px;
  padding: 14px 25px;
  text-decoration: none;
  display: flex;
}

.tarjeta-8:hover {
  background-color: #323644;
}

.div-7 {
  width: 41.67px;
  min-width: 41.67px;
  max-width: 41.67px;
  height: 36.13px;
  min-height: 36.13px;
  max-height: 36.13px;
}

.perfil-alumno-info-modulo {
  grid-row-gap: 10px;
  background-color: #494f63;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 100%;
  min-height: 130px;
  max-height: 180px;
  padding: 16px 31px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-info-titulo-1, .perfil-alumno-info-texto-1 {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-info-lecciones {
  grid-row-gap: 10px;
  background-color: #494f63;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 100%;
  min-height: 130px;
  max-height: 180px;
  padding: 16px 31px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-info-titulo-2, .perfil-alumno-info-texto-2 {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-info-certificado {
  grid-row-gap: 10px;
  border: 1px dashed #4d546c;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: 180px;
  padding: 16px 20px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-info-titulo-3 {
  color: #575b75;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-info-alumno {
  grid-row-gap: 15px;
  border: 1px solid #fff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 207px;
  min-height: 207px;
  max-height: 207px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.perfil-alumno-nombre {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-dato-1 {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.vectors-wrapper-227 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 139.998px;
  height: .777766px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-progreso-contenido {
  grid-row-gap: 29px;
  background-image: linear-gradient(#323644 0%, #32364400 80%);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 298px;
  height: 436px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-progreso-lecciones {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-numero-lecciones {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-lecciones {
  color: #fff;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-progreso-horas {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-horas {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-texto-horas {
  color: #fff;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-progreso-test {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-test {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-texto-test {
  color: #fff;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.div-8, .div-43 {
  width: 39px;
}

.div-44 {
  min-width: 39px;
}

.div-45 {
  max-width: 39px;
}

.div-46 {
  height: 39px;
}

.div-47 {
  min-height: 39px;
}

.div-48 {
  justify-content: center;
  align-items: center;
  max-height: 39px;
  display: flex;
}

.div-49 {
  width: 30.46px;
}

.div-50 {
  min-width: 30.46px;
}

.div-51, .div-52 {
  max-width: 30.46px;
}

.div-53 {
  height: 35.83px;
}

.div-54 {
  min-height: 35.83px;
}

.div-55, .div-56 {
  max-height: 35.83px;
}

.div-57 {
  width: 43px;
  min-width: 43px;
  max-width: 43px;
  height: 43px;
  min-height: 43px;
  max-height: 43px;
}

.boton-general {
  border: 1px solid #4d546c;
  border-radius: 30px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 30px;
  padding: 10px 20px;
  text-decoration: none;
  display: flex;
}

.texto-boton-general {
  color: #4d546c;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 101%;
  text-decoration: none;
}

.perfil-alumno-modulo-1 {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-modulo-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-modulo-leccion-1 {
  grid-column-gap: 8px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-texto-leccion {
  color: #fff;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-leccion-texto-numero {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-modulo-leccion-2, .perfil-alumno-modulo-leccion-3 {
  grid-column-gap: 8px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.filtro-en-curso {
  grid-column-gap: 8px;
  cursor: pointer;
  background-color: #323644;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 125px;
  min-width: 125px;
  max-width: 125px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  padding: 6px;
  text-decoration: none;
  display: flex;
}

.en-curso-1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.filtro-en-curso-seleccionado {
  grid-column-gap: 8px;
  background-color: #32364461;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 125px;
  min-width: 125px;
  max-width: 125px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  padding: 6px;
  text-decoration: none;
  display: flex;
}

.en-curso-2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.filtro-certificado, .filtro-inconcluso {
  grid-column-gap: 8px;
  cursor: pointer;
  background-color: #323644;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 125px;
  min-width: 125px;
  max-width: 125px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  padding: 6px;
  text-decoration: none;
  display: flex;
}

.filtro-certificado-selecc, .filtro-inconcluso-selecc {
  grid-column-gap: 8px;
  background-color: #32364461;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 125px;
  min-width: 125px;
  max-width: 125px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  padding: 6px;
  text-decoration: none;
  display: flex;
}

.div-block-79 {
  border: 1px dashed #b1bdcd73;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
}

.certificado {
  grid-column-gap: 28px;
  background-color: #707585;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 522px;
  height: 199px;
  padding: 14px;
  text-decoration: none;
  display: flex;
}

.certificado-contenido {
  grid-row-gap: 17px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.certificado-titulos {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 45.7249px;
  display: flex;
}

.certificado-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.certificado-subtitulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.certificado-info {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.certificado-info-1, .certificado-info-2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.certificado-info-3 {
  color: #1de8f5;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: underline;
  transition: background-color .2s;
}

.certificado-info-3:hover {
  color: #bdf8fc;
  font-style: italic;
  text-decoration: none;
}

.certificado-boton {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-8-calificacion {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-8-normal {
  cursor: pointer;
  background-color: #26282d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 240px;
  max-width: 298px;
  height: 146px;
  padding: 14px 25px;
  text-decoration: none;
  transition: background-color .25s;
  display: flex;
}

.tarjeta-8-normal:hover {
  background-color: #323644;
}

.perfil-alumno-certificado-obtenido {
  grid-row-gap: 15px;
  color: #a5ff32;
  text-align: center;
  border: 1px dashed #a5ff32;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 145px;
  min-height: 150px;
  max-height: 180px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 16px 20px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 119%;
  text-decoration: none;
  display: flex;
}

.boton-certificado-ver {
  grid-column-gap: 10px;
  cursor: pointer;
  border: 1px solid #a5ff32;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 23px;
  padding: 7px 17px;
  text-decoration: none;
  display: flex;
}

.boton-certificado-ver:hover {
  background-color: #a5ff3266;
  box-shadow: 0 0 20px -6px #a5ff32;
}

.boton-certificado-ver-texto {
  color: #a5ff32;
  width: 74px;
  min-width: 74px;
  max-width: 74px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.property-1-default {
  grid-column-gap: 10px;
  border: 1px solid #a5ff32;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 23px;
  padding: 7px 17px;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-certificado-en-proceso {
  grid-row-gap: 15px;
  color: #1de8f5;
  text-align: center;
  border: 1px dashed #1de8f5;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 145px;
  height: auto;
  min-height: 150px;
  max-height: 180px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 16px 20px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-alumno-certificado-obtenido-titulo {
  color: #a5ff32;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-certificado-en-proceso-titulo {
  color: #1de8f5;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 119%;
  text-decoration: none;
}

.perfil-alumno-certificado-en-proceso-texto {
  color: #1de8f5;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.curso-no-comprado-alumno-body {
  grid-row-gap: 21px;
  background-color: #1d1d1d;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-contenido {
  grid-column-gap: 35px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1297px;
  height: calc(100% - 141px);
  min-height: calc(100% - 141px);
  max-height: calc(100% - 141px);
  text-decoration: none;
  display: flex;
}

.tarjeta-6-curso-disponible-ahora {
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 298px;
  max-width: 298px;
  height: 426px;
  text-decoration: none;
  display: flex;
  position: static;
}

.tarjeta-6-curso-disponible-prox {
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 298px;
  max-width: 298px;
  height: 426px;
  text-decoration: none;
  display: flex;
}

.tarjeta-1-curso-disponible-prox {
  grid-column-gap: 10px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.img-6, .img-7 {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.no-comprado-alumno-descripcion-curso {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-info-1 {
  grid-column-gap: 30px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-calificacion {
  grid-column-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-texto-calificacion {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-graduados {
  grid-column-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-texto-graduados {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-idioma {
  grid-column-gap: 5px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-texto-idioma {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-info-2 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 868px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-lo-que-aprenderas {
  grid-row-gap: 25px;
  background-color: #2e313c;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 696px;
  padding: 30px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-titulo-1 {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-texto-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-texto-info-2 {
  grid-column-gap: 10px;
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-texto-info-1 {
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-check {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 206px;
  height: 34px;
  text-decoration: none;
  display: flex;
}

.check {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  height: 34px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.no-comprado-alumno-texto-check-1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-info-3 {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 964px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-titulo-2 {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-texto-info-3 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-info {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-info-3-listado {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-perfil-egreso-listado {
  grid-column-gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.error-aa6c0f91-126c-f385-f39d-000e307b99a2, .error-7829b67a-e8ec-0e48-0cfd-4012a6c3c76c, .error-843db157-b3c9-69ff-08fa-3c9b2a49146c, .error-e45eccc9-c7a9-2392-2eff-0388ea6fd652, .error-e97eee27-fc63-b9a6-833b-299d92def7a9 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-info-4 {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 964px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-titulo-3 {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-requerimentos {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-requerimento-1 {
  grid-column-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-requerimento-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-requerimento-2 {
  grid-column-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-info-5 {
  grid-row-gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-titulo-4 {
  grid-column-gap: 15px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-contenido-1 {
  grid-row-gap: 43px;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
  overflow-y: auto;
}

.frame-15 {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  height: 19px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.no-comprado-alumno-lo-que-aprenderas-titulo {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-lo-que-aprenderas-texto-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-lo-que-aprenderas-contenido {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-lo-que-aprenderas-texto-contenido-1, .no-comprado-alumno-lo-que-aprenderas-texto-contenido-2 {
  grid-row-gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-contenido-titulo-info-5 {
  grid-column-gap: 15px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-contenidp-texto-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-tabla-contenido {
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-tabla-contenido-1 {
  grid-column-gap: 10px;
  border: 1px solid #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 742px;
  padding: 15px 18px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-tabla-contenido-titulo {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-tabla-contenido-titulo-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-tabla-contenido-lecciones {
  color: #fff;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.no-comprado-alumno-tabla-contenido-2, .no-comprado-alumno-tabla-contenido-3 {
  grid-column-gap: 10px;
  border: 1px solid #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 742px;
  padding: 15px 18px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-tabla-contenido-4 {
  grid-column-gap: 10px;
  border: 1px solid #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 742px;
  padding: 15px 18px;
  text-decoration: none;
  display: flex;
}

.div-58 {
  aspect-ratio: 2 / 3;
  height: 100%;
}

.div-59 {
  aspect-ratio: 2 / 3;
}

.frame-184 {
  grid-column-gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.div-60 {
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
}

.div-61, .div-62, .div-63, .div-64, .div-65, .div-66, .div-67, .div-68, .div-69, .div-70, .div-71, .div-72 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-80 {
  justify-content: center;
  align-items: flex-start;
  width: 5px;
  height: 100%;
  padding-top: 5px;
  display: flex;
}

.div-73 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu-lateral-chico {
  background-color: #323644;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  display: flex;
}

.menu-header {
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.menu-iconos {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.menu-log-out {
  grid-column-gap: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38.75px;
  padding: 10px;
  display: flex;
}

.menu-log-out:hover {
  background-color: #d9d9d91a;
}

.menu-icono-item-seleccionado {
  grid-column-gap: 10px;
  background-color: #d9d9d91a;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  display: flex;
}

.menu-icono-item {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.menu-icono-item:hover {
  background-color: #d9d9d91a;
}

.curso-alumno-contenido {
  grid-column-gap: 35px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1741px;
  height: calc(100% - 128px);
  min-height: calc(100% - 128px);
  max-height: calc(100% - 128px);
  text-decoration: none;
  display: flex;
  position: relative;
}

.curso-alumno-contenido-1 {
  grid-row-gap: 25px;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 442px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.curso-alumno-profesor-video {
  grid-row-gap: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-aprenderas-certificado {
  grid-column-gap: 35px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
  position: relative;
}

.curso-alumno-contenido-2 {
  grid-row-gap: 10px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 390px;
  max-width: 470px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: none;
  position: relative;
}

.curso-alumno-body {
  grid-row-gap: 8px;
  background-color: #1d1d1d;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 64px);
  min-width: calc(100% - 64px);
  max-width: calc(100% - 64px);
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.curso-alumno-profesor-evaluacion {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-profesor {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.perfil-profesor-default {
  grid-column-gap: 10px;
  background-color: #ebfbfe;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  text-decoration: none;
  display: flex;
}

.text {
  color: #0b87ac;
  text-align: center;
  letter-spacing: -.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
}

.curso-alumno-profesor-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.boton-evaluacion {
  grid-column-gap: 10px;
  cursor: pointer;
  border: 1px solid #707585;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 156px;
  height: 36px;
  padding: 4px 10px;
  text-decoration: none;
  transition: background-color .125s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.boton-evaluacion:hover {
  background-color: #fff;
}

.boton-evaluacion-texto {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-titulo-leccion {
  grid-column-gap: 30px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  text-decoration: none;
  display: flex;
}

.curso-alumno-titulo-1 {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-titulo-1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-texto-leccion {
  color: #b1bdcd;
  align-content: center;
  height: 28px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-leccion {
  grid-column-gap: 20px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  text-decoration: none;
  display: flex;
}

.curso-alumno-titulo-leccion-1 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-lo-que-aprenderas {
  grid-row-gap: 25px;
  border: 1px solid #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 222px);
  max-width: 853px;
  padding: 30px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.curso-alumno-titulo-2 {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-texto-info-lo-que-aprenderas {
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  flex-flow: wrap;
  grid-template: "."
                 "."
                 / 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  place-content: stretch space-between;
  place-items: flex-start stretch;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-alumno-check {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 250px;
  max-width: calc(50% - 5px);
  height: 34px;
  display: flex;
}

.tarjeta-certificado-en-progreso {
  grid-row-gap: 8px;
  border: 1px dashed #b1bdcd;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 187px;
  min-width: 187px;
  padding: 10px 15px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.tarjeta-certificado-contenido {
  grid-column-gap: 7px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-certificado-icono {
  background-color: #323644;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 59px;
  min-width: 59px;
  max-width: 59px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  padding: 10px;
  text-decoration: none;
  display: flex;
}

.tarjeta-certificado-titulos {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.tarjeta-certificado-titulo-1 {
  color: #fff;
  align-content: center;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.tarjeta-certificado-titulo-2 {
  color: #707585;
  align-content: center;
  height: 18px;
  min-height: 18px;
  max-height: 18px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.tarjeta-certificado-barra-progreso {
  grid-row-gap: 10px;
  background-color: #323644;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 10px;
  text-decoration: none;
  display: flex;
}

.tarjeta-certificado-progreso {
  background-image: linear-gradient(90deg, #1de8f533, #1de8f5);
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 0%;
  height: 100%;
  text-decoration: none;
  transition: min-width .125s cubic-bezier(.55, .085, .68, .53), max-width .125s cubic-bezier(.55, .085, .68, .53), width .125s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.curso-alumno-descripcion {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 853px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-titulo-descripcion {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-descripcion {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.error-04ead157-6964-f106-2034-64d1c7735092 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-requerimientos {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 853px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-requerimientos-titulo {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-titulo-requerimientos {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-requerimiento-1 {
  grid-column-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-requerimiento {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-requerimiento-2 {
  grid-column-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-reuqerimiento {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-perfil-egreso {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 853px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-perfil-egreso-titulo {
  grid-column-gap: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-titulo-texto-perfil-egreso {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-perfil-egreso-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-perfil-egreso-listado {
  grid-column-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-perfil-egreso-listado-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-info-recursos {
  grid-column-gap: 56px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-info {
  cursor: pointer;
  border-bottom: 0 solid #fff;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: none;
  transition: border-width .1s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.curso-alumno-info:hover {
  border-bottom: 5px solid #fff;
}

.curso-alumno-info-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110.5%;
  text-decoration: none;
}

.curso-alumno-recursos {
  grid-column-gap: 10px;
  cursor: pointer;
  border-bottom: 0 solid #fff;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  text-decoration: none;
  transition: border-width .1s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.curso-alumno-recursos:hover {
  border-bottom: 5px solid #fff;
}

.curso-alumno-recursos-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110.5%;
  text-decoration: none;
}

.curso-alumno-temario {
  grid-column-gap: 10px;
  cursor: pointer;
  border-bottom: 0 solid #fff;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  text-decoration: none;
  transition: border-width .1s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.curso-alumno-temario:hover {
  border-bottom: 5px solid #fff;
}

.curso-alumno-temario-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110.5%;
  text-decoration: none;
}

.curso-alumno-foro {
  grid-column-gap: 10px;
  cursor: pointer;
  border-bottom: 0 solid #fff;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  text-decoration: none;
  transition: border-width .1s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.curso-alumno-foro:hover {
  border-bottom: 5px solid #fff;
}

.curso-alumno-foro-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110.5%;
  text-decoration: none;
}

.curso-alumno-botones-temario-foro {
  grid-column-gap: 20px;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 390px;
  max-width: 470px;
  height: 46px;
  min-height: 46px;
  max-height: 46px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-boton-temario {
  grid-column-gap: 10px;
  background-color: #323644;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 185px;
  height: 46px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
}

.curso-alumno-texto-temario {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110.5%;
  text-decoration: none;
}

.curso-alumno-boton-foro {
  grid-column-gap: 10px;
  cursor: pointer;
  background-color: #25272c;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 185px;
  height: 46px;
  padding-top: 8px;
  padding-bottom: 8px;
  transition: background-color .125s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.curso-alumno-boton-foro:hover {
  background-color: #32364499;
}

.curso-alumno-texto-foro {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-recursos-glosario {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1186px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-glosario {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-recursos-botones-1 {
  grid-column-gap: 42px;
  grid-row-gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-boton-video {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-video {
  color: #1de8f5;
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-boton-infografia {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-infografia {
  color: #ff8d8d;
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-boton-presentacion {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-presentacion {
  color: #a5ff32;
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-boton-plantilla {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-plantilla {
  color: #5ae7c5;
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-recursos1 {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1186px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-recursos1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-recursos-botones-2 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-boton-video-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 1px solid #1de8f5;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 266px;
  height: 41px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  transition: background-color .1s;
  display: flex;
}

.curso-alumno-boton-video-2:hover {
  background-color: #1de8f566;
}

.curso-alumno-boton-infografia-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 1px solid #ff8d8d;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 266px;
  height: 41px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  transition: background-color .1s;
  display: flex;
}

.curso-alumno-boton-infografia-2:hover {
  background-color: #ff8d8d66;
}

.curso-alumno-boton-presentacion-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 1px solid #a5ff32;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 266px;
  height: 41px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  transition: background-color .1s;
  display: flex;
}

.curso-alumno-boton-presentacion-2:hover {
  background-color: #a5ff3266;
}

.curso-alumno-boton-plantilla-2 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  cursor: pointer;
  border: 1px solid #5ae7c5;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 266px;
  height: 41px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  transition: background-color .1s;
  display: flex;
}

.curso-alumno-boton-plantilla-2:hover {
  background-color: #5ae7c566;
}

.curso-alumno-temario-1 {
  grid-column-gap: 14px;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  background-color: #323644;
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 390px;
  height: 713px;
  padding: 15px 20px;
  display: flex;
  position: relative;
  overflow-y: hidden;
}

.curso-alumno-temario-1:hover {
  overflow-y: auto;
}

.curso-alumno-frame-modulos1 {
  grid-row-gap: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 683px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.curso-alumno-modulo1 {
  grid-column-gap: 80px;
  background-color: #707585;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 6px 10px;
  text-decoration: none;
  display: flex;
  position: sticky;
  top: 0;
}

.curso-alumno-texto-modulo1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-leccion2 {
  grid-column-gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  text-decoration: none;
  display: flex;
}

.curso-alumno-check-cirulo1 {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 19px;
  min-width: 19px;
  max-width: 19px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-leccione3 {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-texto-leccion1 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-temario-leccion-contenido1 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-leccion1 {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-circulo1, .curso-alumno-play-circulo1 {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 19px;
  min-width: 19px;
  max-width: 19px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-linea-verde {
  background-color: #a5ff32;
  width: 2px;
  min-width: 2px;
  max-width: 2px;
  height: calc(100% - 23px);
  min-height: calc(100% - 23px);
  max-height: calc(100% - 23px);
}

.curso-alumno-linea-azul {
  background-color: #1de8f5;
  width: 2px;
  min-width: 2px;
  max-width: 2px;
  height: calc(100% - 24px);
  min-height: calc(100% - 24px);
  max-height: calc(100% - 24px);
}

.curso-alumno-linea-gris {
  background-color: #b1bdcd;
  width: 2px;
  min-width: 2px;
  max-width: 2px;
  height: calc(100% - 25px);
  min-height: calc(100% - 25px);
  max-height: calc(100% - 25px);
}

.curso-alumno-tem-1 {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  border: 2px solid #323644;
  border-radius: 20px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 390px;
  max-width: 470px;
  height: calc(100% - 56px);
  min-height: calc(100% - 56px);
  max-height: calc(100% - 56px);
  padding: 25px 20px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.curso-alumno-temario-modulo {
  grid-column-gap: 80px;
  background-color: #707585;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 6px 10px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-texto-modulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-temario-leccion {
  grid-column-gap: 5px;
  color: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-check-cirulo {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 19px;
  min-width: 19px;
  max-width: 19px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-lecciones {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.curso-alumno-temario-texto-leccion {
  align-content: center;
  height: 21px;
  min-height: 21px;
  max-height: 21px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
}

.curso-alumno-temario-nombre-leccion-1 {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  display: flex;
}

.curso-alumno-temario-nombre-texto-leccion {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 21px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-leccion-3 {
  grid-column-gap: 5px;
  color: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-circulo {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 19px;
  min-width: 19px;
  max-width: 19px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-contenido-lecciones-3 {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.curso-alumno-temario-lecciones-3 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.curso-alumno-temario-nombre-leccion {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 21px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-leccion-4 {
  grid-column-gap: 5px;
  color: #707585;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-play-circulo {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-lecciones-4 {
  grid-row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-temario-contenido-lecciones-4 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-tem-2 {
  grid-column-gap: 14px;
  grid-row-gap: 15px;
  border-radius: 20px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 15px 40px;
  display: flex;
}

.img-8 {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
}

.img-9 {
  width: 19px;
}

.img-10 {
  min-width: 19px;
}

.img-11 {
  max-width: 19px;
}

.img-12 {
  height: 19.75px;
}

.img-13 {
  min-height: 19.75px;
}

.img-14 {
  max-height: 19.75px;
}

.img-15, .img-16, .img-17, .img-18, .img-19, .img-20 {
  height: 19.75px;
}

.img-21 {
  height: 0;
}

.img-22, .img-23, .img-24, .img-25, .img-26, .img-27, .img-28, .img-29, .img-30 {
  height: 19.75px;
}

.img-31, .img-32, .img-33, .img-34, .img-35, .img-36, .img-37, .img-38 {
  width: 5px;
}

.img-39 {
  width: 19px;
}

.img-40, .img-41 {
  width: 19.75px;
}

.img-42, .img-43, .img-44, .img-45 {
  width: 19.754px;
}

.img-46 {
  width: 19px;
  min-width: 19px;
  max-width: 19px;
  height: 18px;
  min-height: 18px;
  max-height: 18px;
}

.img-47, .img-48 {
  height: 19px;
}

.img-49, .img-50 {
  overflow: visible;
}

.img-51, .img-52 {
  overflow: clip;
}

.curso-alumno-foro-contenido-principal {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-foro-contenido {
  grid-row-gap: 28px;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: calc(100% - 54px);
  min-height: calc(100% - 54px);
  max-height: calc(100% - 54px);
  padding-right: 14px;
  text-decoration: none;
  display: flex;
  overflow-y: auto;
}

.curso-alumno-frame-modulos {
  grid-row-gap: 15px;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  text-decoration: none;
  display: flex;
  overflow-y: auto;
}

.curso-alumno-foro-titulos {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-foro-titulo {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  text-decoration: none;
}

.curso-alumno-foro-preguntas {
  color: #ffffff85;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  text-decoration: none;
}

.curso-alumno-foro-info {
  grid-column-gap: 21px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-usuario-1 {
  grid-column-gap: 10px;
  background-color: #32ffa9;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  text-decoration: none;
  display: flex;
}

.perfil-usuario-1-texto {
  color: #1d1d1d;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
}

.curso-alumno-foro-pregunta-respuesta {
  grid-row-gap: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-foro-pregunta {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.curso-alumno-foro-texto-pregunta {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  text-decoration: none;
}

.curso-alumno-foro-info-1 {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.curso-alumno-foro-nombre, .curso-alumno-foro-clase, .curso-alumno-foro-dias {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 112.5%;
  text-decoration: none;
}

.curso-alumno-foro--pregunta-contenido {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 120.5%;
  text-decoration: none;
}

.curso-alumno-foro-respuesta {
  grid-column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.perfil-profesor-1 {
  grid-column-gap: 10px;
  background-color: #f98a8a;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  text-decoration: none;
  display: flex;
}

.perfil-profesor-1-texto {
  color: #1d1d1d;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
}

.curso-alumno-foro-respuesta-contenido {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 107px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-foro-texto-profesor {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 112.5%;
  text-decoration: none;
}

.curso-alumno-foro-respuesta-texto {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 120.5%;
  text-decoration: none;
}

.curso-alumno-foro-a-adir-pregunta-perfil {
  grid-column-gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  text-decoration: none;
  display: flex;
}

.perfil {
  grid-row-gap: 0px;
  background-color: #9747ff;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.perfil-texto {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
}

.curso-alumno-foro-a-adir-pregunta {
  border: 1px solid #707585;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.curso-alumno-foro-a-adir-pregunta-texto {
  color: #707585;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
}

.curso-alumno-foro-1 {
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  border-radius: 20px;
  flex-flow: column-reverse;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 15px 40px;
  text-decoration: none;
  display: flex;
}

.curso-alumno-foro-contenido-1 {
  grid-row-gap: 28px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
}

.img-53 {
  cursor: pointer;
  transition: bottom .125s linear;
  position: relative;
  bottom: 0;
}

.img-53:hover {
  position: relative;
  bottom: 2px;
}

.tarjeta-certificado-obtenido {
  grid-row-gap: 8px;
  cursor: pointer;
  border: 1px dashed #b1bdcd;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 187px;
  min-width: 187px;
  padding: 10px 15px;
  text-decoration: none;
  transition: background-color .125s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.tarjeta-certificado-obtenido:hover {
  background-color: #a5ff321a;
}

.tarjeta-certificado-titulo-2-obtenido {
  color: #a5ff32;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
}

.tarjeta-certificado-barra-progreso-completado {
  grid-row-gap: 10px;
  background-color: #323644;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 10px;
  min-height: 10px;
  max-height: 10px;
  text-decoration: none;
  display: flex;
}

.tarjeta-certificado-progreso-completado {
  grid-column-gap: 10px;
  background-image: linear-gradient(90deg, #a5ff32 58%, #a5ff3233);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 0%;
  height: 10px;
  min-height: 10px;
  max-height: 10px;
  text-decoration: none;
  transition: width .125s cubic-bezier(.6, -.28, .735, .045);
  display: flex;
}

.div-block-23-copy {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #323644;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  top: auto;
  bottom: 0%;
}

.curso-alumno-foro-boton-pregunta {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  transition: color .125s cubic-bezier(.55, .085, .68, .53), background-color .125s cubic-bezier(.55, .085, .68, .53);
  display: flex;
}

.curso-alumno-foro-boton-pregunta:hover {
  color: #000;
  background-color: #d5d5d5;
}

.tarjeta-4-curso-barra-progreso {
  background-color: #b1bdcd61;
  border-radius: 10px;
  width: 50%;
  height: 5px;
  min-height: 5px;
  max-height: 5px;
  transition: min-width .125s cubic-bezier(.95, .05, .795, .035), max-width .125s cubic-bezier(.95, .05, .795, .035), width .125s cubic-bezier(.95, .05, .795, .035);
}

.tarjeta-7-contenedor-titulo {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 39px;
  min-height: 39px;
  max-height: 39px;
  display: flex;
}

.tarjeta-3-curso-texto--contenedor-titulo {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  display: flex;
}

.tarjeta-4-curso--contenedor-titulo {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  display: flex;
}

.tarjeta-2-curso-titulo-contenedor-leccion {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
}

.tarjeta-2-curso-contenedor-descripcion {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 57px;
  min-height: 57px;
  max-height: 57px;
  display: flex;
}

.tarjeta-8-contenedor-titulo {
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  display: flex;
}

.iconos-no-access, .iconos-no-accesss {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 2px;
  text-decoration: none;
  display: flex;
}

.iconos-certificado-azul-peque-o {
  grid-column-gap: 10px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 1px;
  text-decoration: none;
  display: flex;
}

.no-comprado-alumno-texto-check-1-copy {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 119%;
  text-decoration: none;
}

.curso-alumno-temario-modulo-contenedor {
  z-index: 1;
  background-color: #1d1d1d;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  text-decoration: none;
  display: flex;
  position: sticky;
  top: 0;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.maintenance {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  background-color: #000;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
}

.image-16 {
  object-fit: scale-down;
}

.astronatauta {
  object-fit: scale-down;
  width: 40vw;
}

.text-maintenance {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 2vw;
  font-weight: 400;
}

.text-maintenance-h1 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 2vw;
  font-weight: 600;
}

.contenedor-textos-maintenance {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-input-md-error {
  color: #bd1a1a;
  width: 100%;
  height: 20px;
  font-size: 12px;
  transition: width .3s cubic-bezier(.55, .085, .68, .53);
  overflow: hidden;
}

.input-content-value-md {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

._10 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 8px;
  font-weight: 700;
  line-height: 100%;
  text-decoration: none;
  position: absolute;
  top: 5.5px;
  left: 6.125px;
}

.menos10 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.mas10 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1px;
  padding-bottom: 1px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.iconos-mas10 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1px;
  text-decoration: none;
  display: flex;
}

.iconos-menos10 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50px;
  height: 50px;
  padding: 1px;
  text-decoration: none;
  display: flex;
}

.img-54 {
  object-fit: scale-down;
  width: 50px;
  height: 50px;
}

.iconos-menos10-2 {
  grid-row-gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1px;
  text-decoration: none;
  display: flex;
}

.vectors-wrapper-228 {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 18px;
  text-decoration: none;
  display: flex;
}

.image-17, .image-18 {
  width: 50%;
}

.error-service-header {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
}

.error-servicios {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #fff;
  background-color: #000;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  display: flex;
}

.error-service-img-astronauta {
  width: 40vw;
}

.error-service-footer {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.error-service-titulo {
  font-family: Poppins, sans-serif;
  font-size: 5vw;
  font-weight: 600;
  line-height: 5vw;
}

.error-service-subtitulo {
  font-size: 2vw;
  line-height: 2vw;
}

.error-service-boton {
  cursor: pointer;
  background-color: #3a3a3a;
  border-radius: 5px;
  padding: 5px 10px;
}

.error-service-boton-texto {
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-size: 1.2vw;
  display: flex;
}

.error-service-reintentar-texto {
  font-family: Poppins, sans-serif;
  font-size: 1vw;
}

@media screen and (min-width: 1330px) {
  .image-1 {
    display: none;
  }

  .item {
    opacity: 0;
  }

  .div-block-51 {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    transition: background-color .125s;
  }

  .div-block-51:hover {
    background-color: #ffffff40;
  }

  .tarjeta-3-curso-contenido {
    background-color: #0000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .tarjeta-3-curso-texto-titulo {
    align-content: center;
  }

  .home-alumno-contenido-principal {
    grid-column-gap: 17.5px;
  }

  .home-alumno-contenido-1 {
    min-width: 807px;
    max-width: 853px;
  }

  .home-alumno-contenido-2 {
    grid-column-gap: 16px;
    grid-row-gap: 21px;
    visibility: visible;
    opacity: 1;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    width: 100%;
    min-width: 354px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    padding-bottom: 10px;
    transition-duration: .5s, .5s, .5s;
    display: flex;
    position: relative;
  }

  .home-alumno-cursos-explora {
    height: calc(100% - 101.54px);
    min-height: calc(100% - 101.54px);
    max-height: calc(100% - 101.54px);
  }

  .home-alumno-tarjeta-cursos-explora {
    scrollbar-gutter: stable;
    padding-right: 14px;
    overflow: hidden auto;
  }

  .home-alumno-tarjeta-cursos-explora:hover {
    overflow-y: auto;
  }

  .img-5 {
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 94px;
    min-width: 94px;
    max-width: 94px;
    height: 140px;
    min-height: 140px;
    max-height: 140px;
  }

  .home-alumno-linea-divisora {
    display: block;
  }

  .tarjeta-5-curso-imagen {
    object-fit: cover;
  }

  .tarjeta-3-curso-proximamente {
    background-color: #323644;
  }

  .tarjeta-3-curso-proximamente:hover {
    background-color: #26282d;
  }

  .perfil-alumno-contenido-2 {
    height: 100%;
    display: flex;
  }

  .perfil-alumno-progreso {
    height: 100%;
  }

  .div-7 {
    width: 41.67px;
    min-width: 41.67px;
    max-width: 41.67px;
    height: 36.13px;
    min-height: 36.13px;
    max-height: 36.13px;
  }

  .perfil-alumno-progreso-contenido {
    height: 100%;
    min-height: 230px;
    max-height: 436px;
  }

  .div-9 {
    min-width: 39px;
  }

  .div-10 {
    max-width: 39px;
  }

  .div-11 {
    width: 0;
  }

  .div-12 {
    width: 39px;
  }

  .div-13 {
    max-width: 15%;
  }

  .div-14, .div-15 {
    width: 39px;
  }

  .div-16, .div-17 {
    min-width: 39px;
  }

  .div-18 {
    width: 39px;
  }

  .div-19 {
    width: 15%;
  }

  .div-20 {
    width: 39svw;
  }

  .div-21, .div-22, .div-23 {
    width: 39px;
  }

  .div-24 {
    min-width: 39px;
  }

  .div-25 {
    max-width: 39px;
  }

  .div-26 {
    max-height: 39px;
  }

  .div-27 {
    min-height: 39px;
  }

  .div-28, .div-29 {
    height: 39px;
  }

  .div-30 {
    display: flex;
  }

  .div-31, .div-32 {
    justify-content: center;
    align-items: center;
  }

  .div-33 {
    justify-content: space-between;
    align-items: center;
  }

  .div-34, .div-35 {
    justify-content: center;
    align-items: center;
  }

  .div-36 {
    justify-content: space-between;
    align-items: center;
  }

  .div-37 {
    display: block;
  }

  .div-38 {
    display: flex;
  }

  .div-39, .div-40, .div-41 {
    justify-content: center;
    align-items: center;
  }

  .div-42 {
    justify-content: space-between;
    align-items: center;
  }

  .img-6 {
    object-fit: contain;
    width: 298px;
    min-width: 298px;
    max-width: 298px;
    height: 140px;
    min-height: 140px;
    max-height: 140px;
  }

  .img-7 {
    object-fit: cover;
    width: 298px;
    min-width: 298px;
    max-width: 298px;
    height: 140px;
    min-height: 140px;
    max-height: 140px;
  }

  .no-comprado-alumno-check {
    max-width: 313px;
  }

  .frame-184 {
    grid-column-gap: 10px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .curso-alumno-contenido-1 {
    min-width: 610px;
  }

  .curso-alumno-contenido-2 {
    display: flex;
  }

  .curso-alumno-temario, .curso-alumno-foro {
    display: none;
  }

  .curso-alumno-tem-2 {
    grid-column-gap: 14px;
    grid-row-gap: 15px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: none;
  }

  .curso-alumno-foro-1 {
    grid-column-gap: 16px;
    grid-row-gap: 20px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: none;
  }

  .div-block-81 {
    color: #b1bdcd;
  }

  .astronatauta {
    max-width: 512px;
  }

  .text-maintenance, .text-maintenance-h1 {
    font-size: 25.5px;
  }

  .error-service-img-astronauta {
    max-width: 512px;
  }

  .error-service-titulo {
    font-size: 65px;
    line-height: 65px;
  }

  .error-service-subtitulo {
    font-size: 25.5px;
    line-height: 25.5px;
  }

  .error-service-boton-texto {
    font-size: 15px;
  }

  .error-service-reintentar-texto {
    font-size: 12px;
  }
}

@media screen and (min-width: 1515px) {
  .section-cnc {
    padding-left: 15%;
    padding-right: 15%;
  }

  .section-info-cnc {
    width: 100%;
    min-width: 700px;
    max-width: 922px;
    margin-right: auto;
  }

  .btn1-md, .btn2-md {
    cursor: pointer;
  }

  .menu-3 {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
  }

  .image-1 {
    object-fit: scale-down;
    display: block;
  }

  .menu-item {
    justify-content: flex-start;
    align-items: center;
    padding-left: 52px;
    padding-right: 52px;
  }

  .item {
    opacity: 1;
    height: 19.0313px;
    display: block;
    overflow: hidden;
  }

  .frame-14 {
    display: flex;
  }

  .frame-30 {
    position: sticky;
  }

  .menu-item-seleccionado {
    justify-content: flex-start;
    align-items: center;
    padding-left: 52px;
    padding-right: 52px;
  }

  .cuestionario-pregunta {
    flex-direction: column;
  }

  .cuestionario-pgt {
    justify-content: space-between;
    height: 100%;
  }

  .cuestionario-contenido {
    flex-direction: column;
    min-width: 250px;
  }

  .cuestionario-info {
    height: 100%;
  }

  .div-block-36 {
    height: 40%;
  }

  .div-block-42 {
    width: 100%;
  }

  .div-block-49 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .frame-6 {
    display: inline;
  }

  .info-3 {
    flex-direction: column;
  }

  .div-block-78 {
    display: none;
  }

  .mis-cursos-alumno-body {
    height: 100px;
  }

  .home-alumno-body {
    width: calc(100% - 250px);
  }

  .home-alumno-contenido-1 {
    max-width: 853px;
  }
}

@media screen and (min-width: 1920px) {
  .contenido-categorias {
    grid-template-columns: 280px 280px 280px 280px;
    place-content: center;
    align-self: center;
    place-items: center;
  }

  .cursos {
    order: 0;
    grid-template-columns: auto auto auto auto;
    align-self: center;
  }

  .text-22 {
    object-fit: fill;
  }

  .section-header-course-no-purchased {
    background-image: linear-gradient(to top, #000, #0000 20%), url('../images/img-header-courses.jpg');
    background-position: 0 0, 50%;
    background-size: auto, cover;
  }

  .section-cnc {
    padding-left: 15%;
    padding-right: 15%;
  }

  .section-info-cnc {
    width: 922px;
    margin-right: 140px;
  }

  .section-cnc-header {
    justify-content: center;
  }

  .div-profesor-2 {
    align-items: center;
  }

  .value-input-md.textotmp {
    color: #0f0;
  }

  .body-2 {
    align-items: flex-start;
  }

  .frame-128 {
    justify-content: flex-end;
  }

  .content-info-curso-recursos {
    padding-right: 50%;
  }

  .section-cnc-a {
    width: 100%;
  }

  .cuestionario-pgt-cen {
    align-items: stretch;
    padding-left: 10%;
    padding-right: 10%;
  }

  .cuestionario-pgt-preguntas {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    display: flex;
  }

  .cuestionario-contenido {
    padding-left: 10%;
    padding-right: 10%;
  }

  .section-header-courses {
    background-image: linear-gradient(to top, #000, #0000 20%), url('../images/img-header-courses.jpg');
    background-position: 0 0, 50%;
    background-size: auto, cover;
  }

  .content-recursos {
    padding-right: 50%;
  }

  .tcm-contenedor {
    grid-auto-columns: auto;
  }

  .div-block-37 {
    background-image: linear-gradient(#323644, #323644);
    border-radius: 20px;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    position: relative;
  }

  .div-block-38 {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    flex-direction: column;
  }

  .div-block-44 {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    display: flex;
  }

  .div-block-48 {
    justify-content: space-around;
  }

  .barra-de-busqueda-perfil {
    cursor: text;
  }

  .frame-132 {
    cursor: pointer;
  }

  .tarjeta-curso-contenido {
    max-width: 100px;
  }

  .text-106 {
    text-align: left;
  }

  .tarjeta-curso-info2 {
    justify-content: space-between;
    align-items: flex-start;
  }

  .nuevos-cursos {
    width: 450px;
  }

  .frame-cursos {
    grid-template-columns: auto auto auto auto;
  }

  .curso-explorar {
    width: 100%;
  }

  .info-3 {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }

  .calificaciongraduados, .frame-hora-y-lecciones {
    justify-content: space-between;
    align-items: center;
  }

  .text-106-not-purchased {
    aspect-ratio: 1;
    white-space: normal;
    word-break: normal;
    overflow-wrap: normal;
    align-items: center;
    width: 100%;
    height: 40px;
    display: block;
    overflow: hidden;
  }

  .tarjeta-4-curso-sin-acceso {
    height: 31px;
  }
}

@media screen and (max-width: 991px) {
  .contenido-categorias {
    grid-template-columns: 280px 280px;
  }

  .header {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .botones-inicio-sesion-2 {
    justify-content: center;
  }

  .section-group {
    padding-left: 5%;
    padding-right: 5%;
  }

  .cursos {
    grid-column-gap: 10px;
    grid-template-rows: auto;
    grid-auto-columns: auto;
    grid-auto-flow: row;
  }

  .md-contactanos {
    flex-flow: column;
    width: auto;
    display: flex;
  }

  .div-md-contactanos-der {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .div-input-row {
    grid-template-columns: repeat(auto-fit, minmax(150px, 100%));
    align-content: start;
    place-items: center;
  }

  .input-md {
    align-items: flex-start;
  }

  .text-input-md {
    font-size: 14px;
  }

  .value-input-md, .text-btn2-md {
    font-size: 12px;
  }

  .div-md-contactanos-izq {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    height: auto;
  }

  .title-md {
    font-size: 28px;
  }

  .text-btn1-md {
    font-size: 12px;
  }

  .md-login {
    width: 100%;
  }

  .subtitle-md {
    font-size: 18px;
  }

  .text-comillas-md {
    font-size: 60px;
  }

  .text-cita-md {
    font-size: 18px;
  }

  .autor-cita-md, .text2-md {
    font-size: 12px;
  }

  .text1-md {
    font-size: 14px;
  }

  .md-signup {
    flex-flow: column-reverse;
    justify-content: center;
    align-items: center;
    width: auto;
    display: flex;
  }

  .div-md-signup-izq {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .div-md-signup-der {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0;
    height: auto;
  }

  .text-31 {
    width: auto;
  }

  .frame-21 {
    flex-flow: column;
    height: auto;
  }

  .vectors-wrapper-45 {
    width: 1px;
    height: 250px;
    min-height: 100%;
    max-height: 100%;
  }

  .frame-22 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    max-height: 100%;
  }

  .text-33 {
    height: 100%;
  }

  .error-219fdc8b-a845-1607-b55a-8184c0292da2 {
    height: 100%;
  }

  .frame-23 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: column;
    width: 40%;
  }

  .frame-24, .frame-25 {
    width: 276px;
    max-width: 276px;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .frame-29 {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }

  .text-37 {
    width: 100%;
  }

  .menu-3 {
    justify-content: space-between;
    align-items: flex-start;
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    min-height: 100%;
    max-height: 100%;
    transition: width .2s;
  }

  .image-1, .item, .frame-14 {
    display: none;
  }

  .body-3 {
    height: 100%;
    min-height: 100%;
  }

  .menu-item-seleccionado {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }

  .vectors-wrapper-46 {
    width: 100%;
    height: 1px;
    display: none;
  }

  .vectors-wrapper-44 {
    width: 100%;
    height: 1px;
  }

  .div-block-77 {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .img {
    display: inline-block;
  }

  .div-block-78 {
    display: block;
  }

  .home-alumno-contenido-1 {
    min-width: auto;
  }
}

@media screen and (max-width: 767px) {
  .contenido-categorias {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    overflow: auto;
  }

  .botones-inicio-sesion-2 {
    justify-content: center;
  }

  .barra-busqueda {
    width: 100%;
  }

  .cursos {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    overflow: auto;
  }

  .text-input-md {
    font-size: 14px;
  }

  .butons-md {
    place-content: center;
    place-items: center end;
  }

  .title-md {
    font-size: 24px;
    line-height: 30px;
  }

  .md-login {
    width: auto;
    display: flex;
  }

  .div-md-login-der {
    display: none;
  }

  .div-md-login-izq {
    border-radius: 10px;
    max-width: none;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .sect-footer-datos {
    flex-flow: column;
  }
}

@media screen and (max-width: 479px) {
  .contenido-categorias {
    flex-direction: row;
    grid-template-columns: auto;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-items: center;
    display: flex;
    overflow: auto;
  }

  .categoria {
    width: 80%;
    min-width: 250px;
    height: 80px;
  }

  .vectors-wrapper-2 {
    width: 50px;
    height: 50px;
  }

  .categoria-titullo, .categoria-num-cursos {
    font-size: 14px;
  }

  .header-menu-2 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-direction: column;
  }

  .botones-inicio-sesion-2 {
    justify-content: center;
  }

  .mega-text {
    font-size: 38px;
  }

  .text-3 {
    font-size: 18px;
  }

  .vectors-wrapper-3 {
    width: 50px;
    height: 50px;
  }

  .text-4 {
    font-size: 18px;
  }

  .text-5 {
    font-size: 14px;
  }

  .section-text {
    font-size: 20px;
  }

  .cursos {
    flex-direction: row;
    grid-template-columns: 1fr;
    display: flex;
    overflow: auto;
  }

  .curso {
    min-width: 300px;
    max-width: 300px;
    height: 350px;
  }

  .section-text-footer {
    font-size: 20px;
  }

  .md-contactanos {
    flex-direction: column;
    display: flex;
  }

  .div-md-contactanos-der {
    border-radius: 0 0 10px 10px;
  }

  .div-md-contactanos-izq {
    border-radius: 10px 10px 0 0;
  }

  .icon-contacto-md {
    width: 24px;
    height: 24px;
  }

  .div-md-login-der {
    border-radius: 10px 10px 0 0;
    height: 350px;
    display: none;
  }

  .subtitle-md {
    font-size: 18px;
  }

  .md-signup {
    flex-direction: column-reverse;
    display: flex;
  }

  .div-md-signup-izq {
    border-radius: 10px;
  }

  .div-md-signup-der {
    display: none;
  }

  .curso-info-more {
    min-width: 300px;
    max-width: 300px;
    height: 350px;
  }

  .curso-nuevo-titulo {
    font-size: 18px;
  }

  .texto-calificacion-graduados {
    font-size: 14px;
  }

  .curso-normal {
    min-width: 300px;
    max-width: 300px;
    height: 350px;
  }

  .text-contacto-md-footer {
    font-size: 14px;
  }

  .section-text-footer1 {
    font-size: 20px;
  }

  .error-service-header {
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
  }
}


@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-BoldItalic.ttf') format('truetype'), url('../fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraBold.ttf') format('truetype'), url('../fonts/Poppins-ExtraBold.ttf') format('truetype'), url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype'), url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-MediumItalic.ttf') format('truetype'), url('../fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Dhurjati';
  src: url('../fonts/Dhurjati-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Renogare';
  src: url('../fonts/Renogare-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}