:where(.css-dev-only-do-not-override-nwywq7).ant-modal .ant-modal-content {
  background-color: #323644 !important;
  border-radius: 20px !important;
  color: #fff !important;
  font-family: Poppins, sans-serif !important;
  /*(padding: 35px 60px !important;*/
}
/* Selector de alta especificidad para sobrescribir el ancho del modal */
.ant-modal.css-dev-only-do-not-override-nwywq7 {
  width: 40% !important;
}

.row-par {
  background-color: #3a3e4c; /* color para filas pares */
  color: #ffff;

  cursor: pointer;
}

.row-impar {
  cursor: pointer;
  background-color: #323644; /* color para filas impares */
  color: #ffff;
}

.custom-table {
  width: 100% !important;
}
.custom-table .ant-table-tbody > tr > td {
  border-bottom: none !important;

  font-size: 12px;
  font-style: normal;
  font-family: Poppins, sans-serif;
}

.custom-table .ant-table-thead > tr > th {
  border-bottom: none !important;
  background-color: transparent !important; /* Hace el fondo de las celdas del encabezado transparente */
  color: #fff !important; /* Cambia el color del texto del encabezado a blanco */

  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  font-style: bold;
  font-family: Poppins, sans-serif;
}

.custom-table.ant-table-wrapper .ant-table {
  background-color: transparent !important;
}

.custom-table .ant-table-tbody > tr > td {
  background-color: transparent !important; /* Hace el fondo de las celdas transparente */
  color: #fff !important; /* Cambia el color del texto a blanco */
}

/* Sobrescribe los estilos de los pseudo-elementos que añaden los separadores */
.ant-table-thead > tr > th::before,
.ant-table-thead > tr > td::before {
  display: none !important;
}

/* O si necesitas ser más específico debido a la presencia de .css-dev-only-do-not-override-nwywq7 */
:where(.ant-table-wrapper) .ant-table-thead > tr > th::before,
:where(.ant-table-wrapper) .ant-table-thead > tr > td::before {
  display: none !important;
}

/* Bode radius*/

/* Aplica un border-radius a la primera fila con !important */
.custom-table .ant-table-tbody > tr:first-child > td:first-child {
  border-top-left-radius: 8px !important; /* Ajusta el radio según sea necesario */
}

.custom-table .ant-table-tbody > tr:first-child > td:last-child {
  border-top-right-radius: 8px !important; /* Ajusta el radio según sea necesario */
}

/* Aplica un border-radius a la última fila con !important */
.custom-table .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 8px !important; /* Ajusta el radio según sea necesario */
}

.custom-table .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px !important; /* Ajusta el radio según sea necesario */
}

.btn-curso-profesor-seleccionado {
  width: 170px;
  height: 45px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 9px 30px;
  display: flex;

  color: #1d1d1d;

  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  );
}

:where(.css-dev-only-do-not-override-nwywq7).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  color: rgb(255 255 255) !important;
  background-color: #494f63 !important;
  border-radius: 10px !important;
  font-family: Poppins, sans-serif !important;
}
:where(.css-dev-only-do-not-override-nwywq7).ant-collapse
  .ant-collapse-content {
  color: rgba(255, 255, 255, 0.88);
  background-color: #00000000;
  border-top: 1px solid #00000000;
  font-family: Poppins, sans-serif !important;
}

:where(.css-dev-only-do-not-override-nwywq7).ant-collapse-borderless
  > .ant-collapse-item:last-child {
  margin-bottom: 0;
}

:where(.css-dev-only-do-not-override-nwywq7).ant-collapse-borderless
  > .ant-collapse-item {
  border-bottom: none;
  margin-bottom: 15px;
  font-family: Poppins, sans-serif !important;
}
:where(.css-dev-only-do-not-override-nwywq7).ant-collapse > .ant-collapse-item {
  margin-bottom: 15px;
  border-bottom: none;
  font-family: Poppins, sans-serif !important;
}

.wrapper-class {
  position: relative;
  height: calc(100% - 50px);
}
.toolbar-class {
  background: none !important;
  border: none !important;
}

.rdw-option-wrapper {
  border: none !important;
  background: none !important;
}
.custom-bold-class {
  display: inline-block;
  vertical-align: middle;
  width: 24px; /* Ancho del icono */
  height: 24px; /* Altura del icono */
}

.div-contenido-for-1 {
  overflow-y: auto;
  padding-right: 10px;
}
.div-block-68 {
  height: 100% !important;
}

.tox-tinymce {
  border: none !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background: none !important;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: none !important;
  display: flex;
  justify-content: end;
}
.tox .tox-toolbar-overlord {
  background: none !important;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background: none !important;
}

.tox .tox-tbtn svg {
  fill: #1de8f5 !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover {
  background: #485769 !important
  ;
}

.tox .tox-tbtn:hover {
  background: #485769 !important;
}

.tox .tox-edit-area__iframe {
  background: none !important;
  border: 1px solid #707585 !important;
  border-radius: 10px !important;
  font-size: 12px !important;
}

.mce-content-body {
  color: #fff !important;
}

.tox .tox-menu.tox-collection.tox-collection--toolbar {
  background: #707585 !important;
}

.tox .tox-collection--toolbar .tox-collection__item--active {
  background-color: #aeb7c0;
  visibility: "hidden";
}
