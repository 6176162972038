.cursor-seleccion {
  cursor: pointer;
}
.cursor-tache {
  cursor: not-allowed !important;
}

.invisible {
  visibility: hidden;
}

.barra-buscar-texto {
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin bordes */
  outline: none; /* Eliminar el resalte al enfocar */
  box-shadow: none; /* Sin sombras */
}

.contenido-temariocalificacion {
  width: calc(100% - 320px) !important;
}

/*
 barra de desplazamiento (scrollbar)
*/
/*
 Navegadores Basados en Chromium
*/
/* Personalizando la barra de desplazamiento en su totalidad */
.progreso-curso-cursos::-webkit-scrollbar {
  height: 10px; /* Ancho de la barra de desplazamiento */
}
.contenedo-card-prox-vencer::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
}

.infinite-scroll-component__outerdiv {
    grid-row-gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 92px);;
    min-height: calc(100% - 92px);;
    max-height: calc(100% - 92px);;
    text-decoration: none;
    display: flex;
    position: relative;

}

.tcm-vigencia-vencida-certificado {
  color: #1de8f5;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 119%;
}

.text-106-not-purchased {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}


.link-style.tam{
  width: auto !important;
}