.item-menu-2:hover {
  border-bottom: 3px solid#ffff;
  padding-bottom: 5px;
}
.item_seleccionado {
  border-bottom: 3px solid#ffff;
  padding-bottom: 5px;
}

.btn-infografia-recursos:hover {
  background-color: #ff8d8d;
  color: #ffff;
}

.vectors-wrapper-100:hover {
  color: red;
}
