.text-177 {
  overflow-y: auto !important;
  padding-right: 5px;
}


.tarjeta-curso-1:hover .card-container {
  transform: rotateY(180deg);
}

.card-container {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  border-radius: l0px;
}

.card-face {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: lpx;
}

.front-face {
  transform: rotateY(0deg);
}
.back-face {
  display: flex;
  transform: rotateY(180deg);
}
