input {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  border: 2px dashed rgb(114, 114, 114);
}
input.text-145 {
  border: none !important;
}

textarea {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 1;
  border: 2px dashed rgb(114, 114, 114);
  resize: none; /* Evita que el usuario redimensione el textarea */
  overflow: hidden; /* Oculta la barra de desplazamiento */
  transition: height 0.2s ease-out; /* Transición suave para la altura */
  height: auto;
}

.error {
  border: 2px dashed rgb(172, 31, 31);
  border-radius: 5px;
}

.miElemento {
  transition: transform 0.5s ease-out;
}

.ant-modal.css-dev-only-do-not-override-nwywq7 {
  width: 40% !important;
}

:where(.css-dev-only-do-not-override-nwywq7).ant-modal .ant-modal-content {
  background-color: #323644 !important;
  border-radius: 20px !important;
  color: #fff !important;
  font-family: Poppins, sans-serif !important;
  padding: 0px 0px !important;
}
