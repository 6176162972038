:where(.css-dev-only-do-not-override-1nw500j).ant-input-borderless,
:where(.css-dev-only-do-not-override-1nw500j).ant-input-borderless:hover,
:where(.css-dev-only-do-not-override-1nw500j).ant-input-borderless:focus,
:where(.css-dev-only-do-not-override-1nw500j).ant-input-borderless-focused,
:where(.css-dev-only-do-not-override-1nw500j).ant-input-borderless-disabled,
:where(.css-dev-only-do-not-override-1nw500j).ant-input-borderless[disabled] {
  width: 100%;
  padding-bottom: 5px;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #707585;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: Poppins;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 600;
}

:where(
    .css-dev-only-do-not-override-1nw500j
  ).ant-input-affix-wrapper-borderless,
:where(
    .css-dev-only-do-not-override-1nw500j
  ).ant-input-affix-wrapper-borderless:hover,
:where(
    .css-dev-only-do-not-override-1nw500j
  ).ant-input-affix-wrapper-borderless:focus,
:where(
    .css-dev-only-do-not-override-1nw500j
  ).ant-input-affix-wrapper-borderless-focused,
:where(
    .css-dev-only-do-not-override-1nw500j
  ).ant-input-affix-wrapper-borderless-disabled,
:where(
    .css-dev-only-do-not-override-1nw500j
  ).ant-input-affix-wrapper-borderless[disabled] {
  width: 100% !important;
  padding-bottom: 5px !important;
  border-bottom-style: solid !important;
  border-bottom-width: 4px !important;
  border-bottom-color: #707585 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-family: Poppins;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 600;
}

:where(.css-dev-only-do-not-override-1nw500j).ant-input {
  padding: 0;
}

:where(.css-dev-only-do-not-override-1nw500j).ant-input-affix-wrapper:focus,
:where(
    .css-dev-only-do-not-override-1nw500j
  ).ant-input-affix-wrapper:focus-within {
  padding: 0;

  border-width: 0px;
}

:where(.css-dev-only-do-not-override-1nw500j).ant-input-affix-wrapper {
  padding: 0;

  border-width: 0px;
}

:where(.css-1nw500j).ant-input-borderless,
:where(.css-1nw500j).ant-input-borderless:hover,
:where(.css-1nw500j).ant-input-borderless:focus,
:where(.css-1nw500j).ant-input-borderless-focused,
:where(.css-1nw500j).ant-input-borderless-disabled,
:where(.css-1nw500j).ant-input-borderless[disabled] {
  width: 100%;
  padding-bottom: 5px;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #707585;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: Poppins;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 600;
}

:where(.css-1nw500j).ant-input-affix-wrapper-borderless,
:where(.css-1nw500j).ant-input-affix-wrapper-borderless:hover,
:where(.css-1nw500j).ant-input-affix-wrapper-borderless:focus,
:where(.css-1nw500j).ant-input-affix-wrapper-borderless-focused,
:where(.css-1nw500j).ant-input-affix-wrapper-borderless-disabled,
:where(.css-1nw500j).ant-input-affix-wrapper-borderless[disabled] {
  width: 100% !important;
  padding-bottom: 5px !important;
  border-bottom-style: solid !important;
  border-bottom-width: 4px !important;
  border-bottom-color: #707585 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-family: Poppins;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 600;
}

:where(.css-1nw500j).ant-input {
  padding: 0;
}

:where(.css-1nw500j).ant-input-affix-wrapper:focus,
:where(.css-1nw500j).ant-input-affix-wrapper:focus-within {
  padding: 0;

  border-width: 0px;
}

:where(.css-1nw500j).ant-input-affix-wrapper {
  padding: 0;

  border-width: 0px;
}
