.particle-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.particle {
  position: absolute;
  border-radius: 50%;
  animation: moveParticle linear infinite;
}

@keyframes moveParticle {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
