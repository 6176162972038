/*
 barra de desplazamiento (scrollbar)
*/
/*
 Navegadores Basados en Chromium
*/
/* Personalizando la barra de desplazamiento en su totalidad */
.temario-contenido::-webkit-scrollbar {
  width: 7px; /* Ancho de la barra de desplazamiento */
}
.preguntas-foro::-webkit-scrollbar {
  width: 7px; /* Ancho de la barra de desplazamiento */
}

.seleccionado {
  background-color: #323644 !important;
}

.foro:hover {
  background-color: #32364441;
}

.temario:hover {
  background-color: #32364441;
}

.pointer {
  cursor: pointer;
}

.text-block-21 {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  resize: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: spin 3s linear infinite; /* ajusta la duración como prefieras */
}

.line-connector {
  position: absolute;
  top: calc(
    42px + var(--line-connector-top)
  ); /* Ajusta para alinear con el centro del comentario */
  left: 20px; /* Ajusta para alinear con el borde del comentario */
  width: 30px;
  height: 20px;
  background: transparent;
  border: 1px solid #707585;
  border-radius: 0 0 0 50px; /* Redondeo solo en la esquina superior derecha */
  border-right: none;
  border-top: none;
}

.line-connector::after {
  content: "";
  position: absolute;
  top: calc(
    -1 * var(--line-connector-top)
  ); /* Ajusta para continuar desde el final de la curva */
  left: -1px;
  width: 1px; /* Grosor de la línea */
  height: var(--line-connector-top); /* Ajusta según la distancia al .reply */
  background: #707585;
}
