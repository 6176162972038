body {
  background-color: #272a37;
}

.box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.box .botones-inicio-wrapper {
  border: 0px none;
  height: 48px;
  width: 860px;
  display: flex;
}

.boton-iniciar-sesion {
  align-items: center;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 9px 17px;
  position: relative;
  background-color: transparent;

  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.boton-iniciar-sesion .seleccionado {
  border: 1px solid;
  border-color: #ffffff;
}

.boton-solicitar {
  align-items: center;
  border-radius: 30px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 9px 12px;
  position: relative;
  background-color: transparent;
  border: none;

  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
}

.modalLogin {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  height: 100%;
}

.modalLogin .log-in {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.modalLogin .izquierda {
  align-items: center;
  align-self: stretch;
  background-color: #272a37;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 60px;
  justify-content: center;
  position: relative;
}

.modalLogin .titulos-iniciales {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 0px 43px;
  position: relative;
}

.modalLogin .titulo1 {
  align-self: stretch;
  color: #b1bdcd;
  font-family: Poppins;
  font-size: 20px;
  line-height: normal;
  position: relative;
  margin: 0;
}

.modalLogin .titulo2 {
  align-self: stretch;
  color: transparent;
  font-family: Poppins;
  font-size: 32px;
  line-height: normal;
  position: relative;
  color: #ffffff;
  margin: 0;
}

.modalLogin .text-wrapper-2 {
  color: #1d90f5;
  cursor: pointer;
}

.modalLogin .titulo3 {
  align-self: stretch;
  color: transparent;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.modalLogin .text-wrapper-3 {
  color: #b1bdcd;
}
.modalLogin .form {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 66px;
  padding: 0px 45px;
  position: relative;
}

.modalLogin .inputs {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 27px;
  position: relative;
  width: 100%;
}

.modalLogin .input-instance {
  align-self: stretch !important;
  width: 100% !important;
}

.modalLogin .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 25px;
  justify-content: flex-end;
  position: relative;
}
.modalLogin .fila {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 25px;
  justify-content: flex-end;
  position: relative;

  width: 100%;
}

.modalLogin .btn-cancelar {
  background-color: #324658;
  border-radius: 30px;
  height: 44px;
  position: relative;
  width: 192px;

  font-family: Poppins;
  color: #ffffff;
  font-size: 20px;
}

.modalLogin .btn-inicial-sesion {
  background-color: #1d90f5;
  border-radius: 30px;
  height: 44px;
  position: relative;
  width: 192px;

  font-family: Poppins;
  color: #ffffff;
  font-size: 20px;
}

.modalLogin .derecha-login {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../images/img-login.webp);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 10px 30px 20px;
  position: relative;
}
.modalLogin .derecha-singup {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../images/img-singup.webp);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 10px 30px 20px;
  position: relative;
}

.modalLogin .seleccion {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 13px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.boton-iniciar-sesion {
  align-items: center;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 9px 17px;
  position: relative;
  width: 185px;
}

.boton-iniciar-sesion .inicia-sesi-n {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.boton-iniciar-sesion.seleccionado {
  border: 1px solid;
  border-color: #ffffff;
}

.boton-solicitar {
  align-items: center;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 9px 12px;
  position: relative;
  width: 185px;
}

.boton-solicitar .solicita-una-cuenta {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.boton-solicitar.property-1-seleccionado {
  border: 1px solid;
  border-color: #ffffff;
}

.modalLogin .cita {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  margin: 0;

  padding-bottom: 30px;
}

.modalLogin .comilla {
  align-self: stretch;
  color: #ffffff;
  font-family: "Dhurjati";
  font-size: 70px;
  position: relative;
  margin: 0;
  height: 60px;
}

.modalLogin .quote {
  align-self: stretch;
  color: #ffffff;
  font-family: Poppins;
  font-size: 24px;
  position: relative;
  margin: 0;
}

.modalLogin .author {
  align-self: stretch;
  color: #ffffff;
  font-family: Poppins;
  font-size: 15px;
  text-align: right;
  margin: 0;
}

.modalLogin .nav {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9px;
  position: relative;
}

.modalLogin .overlap-group-wrapper {
  height: 41px;
  position: relative;
  width: 41px;
}

.modalLogin .overlap-group-2 {
  border-radius: 20.5px;
  height: 41px;
  position: relative;
}


