.input {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-color: #707585;
  display: flex;
  flex-direction: column;
  height: 52px;
  position: relative;
}

.input .nombre {
  align-self: stretch;
  color: #b1bdcd;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -4px;
  position: relative;
}

.valor {
  color: #ffffff !important;
  font-family: Poppins;
  font-size: 16px;
  line-height: normal;
  margin-bottom: -10px;
}
.valor {
  color: #ffffff !important;
  font-family: Poppins;
  font-size: 16px;
  line-height: normal;
  margin-bottom: -10px;
}

.mdd {
  width: 100%;
}


.value-input-md::placeholder{
  color: #ffffff57;
}