.glitch-text-titulo {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  transform: translateZ(0);
  font-size: 48px;
  color: white;
}

.glitch-text-titulo::before,
.glitch-text-titulo::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip: rect(0, 900px, 100px, 0); /* Ajuste del clip */
}

.glitch-text-titulo::before {
  left: 2px;
  
  animation: glitch-error 2s infinite linear alternate-reverse;
}

.glitch-text-titulo::after {
  left: -2px;
  
  animation: glitch-error 2s infinite linear alternate-reverse;
}

@keyframes glitch-error {
  0% {
    clip: rect(42px, 9999px, 44px, 0);
    transform: skew(0.10deg);
  }
  5% {
    clip: rect(88px, 9999px, 56px, 0);
    transform: skew(0.6deg);
  }
  10% {
    clip: rect(14px, 9999px, 75px, 0);
    transform: skew(0.4deg);
  }
  15% {
    clip: rect(65px, 9999px, 14px, 0);
    transform: skew(0.2deg);
  }
  20% {
    clip: rect(5px, 9999px, 85px, 0);
    transform: skew(0.3deg) translate(10px, -5px);
  }
  25% {
    clip: rect(90px, 9999px, 20px, 0);
    transform: skew(0.4deg);
  }
  30% {
    clip: rect(35px, 9999px, 45px, 0);
    transform: skew(0.6deg);
  }
  35% {
    clip: rect(25px, 9999px, 85px, 0);
    transform: skew(0.3deg);
  }
  40% {
    clip: rect(95px, 9999px, 95px, 0);
    transform: skew(0.4deg) translate(-10px, 10px);
  }
  45% {
    clip: rect(75px, 9999px, 5px, 0);
    transform: skew(0.2deg);
  }
  50% {
    clip: rect(0px, 9999px, 0px, 0);
    transform: skew(0.4deg);
  }
  55% {
    clip: rect(30px, 9999px, 60px, 0);
    transform: skew(0.6deg);
  }
  60% {
    clip: rect(70px, 9999px, 90px, 0);
    transform: skew(0.3deg) translate(5px, -15px);
  }
  65% {
    clip: rect(80px, 9999px, 10px, 0);
    transform: skew(0.5deg);
  }
  70% {
    clip: rect(50px, 9999px, 50px, 0);
    transform: skew(0.2deg) translate(-5px, 20px);
  }
  75% {
    clip: rect(90px, 9999px, 90px, 0);
    transform: skew(0.4deg);
  }
  80% {
    clip: rect(40px, 9999px, 20px, 0);
    transform: skew(0.6deg) translate(15px, -10px);
  }
  85% {
    clip: rect(60px, 9999px, 80px, 0);
    transform: skew(0.3deg);
  }
  90% {
    clip: rect(20px, 9999px, 60px, 0);
    transform: skew(0.5deg) translate(-10px, 5px);
  }
  95% {
    clip: rect(10px, 9999px, 70px, 0);
    transform: skew(0.4deg);
  }
  100% {
    clip: rect(50px, 9999px, 100px, 0);
    transform: skew(0.3deg) translate(0px, 0px);
  }
}

#glitch-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.glitch-box {
  position: absolute;
  background: rgba(255, 255, 255, 1);
  mix-blend-mode: difference;
  animation: glitch-animation 3s infinite;
}

@keyframes glitch-animation {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-5px, -5px);
  }
  40% {
    transform: translate(5px, -5px);
  }
  60% {
    transform: translate(-5px, 5px);
  }
  80% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
