/*
 barra de desplazamiento (scrollbar)
*/
/*
 Navegadores Basados en Chromium
*/
/* Personalizando la barra de desplazamiento en su totalidad */
::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de desplazamiento */
}

/* Personalizando la pista (track) de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background: #b9b9b9; /* Color de fondo de la pista */
  border-radius: 5px; /* Bordes redondeados para la pista */
}

/* Personalizando el pulgar (thumb) de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background: #5c5c5c; /* Color de fondo del pulgar */
  border-radius: 5px; /* Bordes redondeados para el pulgar */
}

/* Cambio de color al pasar el mouse */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color de fondo del pulgar al pasar el mouse */
}

/*
 Safari
*/

.link-style {
  text-decoration: none;
  color: inherit; /* o cualquier otro color que desees */
  width: 100%;
}

.categoria:hover {
  flex: 1; /* Esto asegura que el contenedor hijo se expanda para llenar el espacio disponible si estás usando Flexbox */

  width: 100%;
  height: 100px;
  max-width: 280px;
  min-width: 280px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  color: #333;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  transition-duration: 0.2s;
}

.item-menu:hover {
  border: 0 solid #fff;
  border-bottom-width: 5px;
}

.btn1:hover {
  color: #ffffff;
  background-color: #323644;
  border: 1px #323644;
  transition-duration: 0.1s;
}

.btn2 {
  background-color: #9b9b9b69;
}
.btn2:hover {
  color: #333;
  border: 1px solid #fff;
  background-color: #fff;
  transition-duration: 0.1s;
}

.btn-card-curso:hover {
  color: #333;
  border: 1px solid #fff;
  background-color: #fff;
  transition-duration: 0.1s;
}

.fade {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s linear, visibility 0.3s linear;
  height: 100%;
}

.fade-out {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  padding: 0px;
  border-radius: 0px;
  transition: opacity 0.2s linear, max-height 0.2s ease-in-out,
    visibility 0s linear 0.2s;
}

/* Tu archivo CSS */
.ant-modal {
  max-width: 100%;
  width: auto !important;
}

.truncated-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* Número de líneas que deseas mostrar antes de truncar */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important; /* Cambiado de nowrap a normal para permitir el salto de línea */
  width: 100% !important;
}
.truncated-text1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* Número de líneas que deseas mostrar antes de truncar */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important; /* Cambiado de nowrap a normal para permitir el salto de línea */
  width: 100% !important;
}
.truncated-text3 {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important; /* Número de líneas que deseas mostrar antes de truncar */
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: normal !important; /* Cambiado de nowrap a normal para permitir el salto de línea */
  width: 100% !important;
}

.input-modal > .ant-row {
  width: 100% !important;
}
.input-textarea-modal > .ant-row {
  width: 100% !important;
}

.btn2-md-crr > .ant-btn {
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.text2-md {
  cursor: pointer;
  width: fit-content;
}

:where(.css-dev-only-do-not-override-1nw500j).ant-input {
  padding: 0;
}




/* InvisibleScroll.css */
.invisible-scroll-container {
  width: 100%;
  height: 300px; /* Altura del contenedor con scroll */
  overflow-y: scroll;
}

.invisible-scroll-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Hacer la barra de scroll invisible */
}

.invisible-scroll-container::-webkit-scrollbar-thumb {
  background: #ff0000; /* Solo por si quieres ponerle color a la barra */
  border-radius: 10px;
}

.invisible-scroll-container {
  scrollbar-width: none; /* Para Firefox */
}

.invisible-scroll-container {
  -ms-overflow-style: none;  /* Para IE y Edge */
}
